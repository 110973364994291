<script>
import Vue from 'vue'
import {mapActions, mapState} from 'pinia'
import useRootStore from '@/stores/rootStore';
import { PushEntity, PushType } from '@/utils/constants';
export default {
  data() {
    return {
      articles: null,
      categories: []
    }
  },
  computed: {
    ...mapState(useRootStore, ['adminCategories', 'adminDataChange'])
  },
  watch: {
    adminDataChange: {
      deep: true,
      handler(val) {
        this.pushUpdate(val)
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_AUCTIONS_FETCHED_ARTICLE', 'REMOVE_AUCTIONS_FETCHED_ARTICLE', 'ADD_AUCTIONS_FETCHED_ARTICLE', 'SET_ADMIN_CATEGORIES']),
    pushUpdate(data) {
      if (data.entity === PushEntity.article) {
        const auctionId = data.data.auction_id
        if (data.type === PushType.update) {
          const updatedArticles = this.articles.map(article => {
            if (article.id === data.data.id) {
              return {
                ...article,
                ...data.data
              }
            }
            return article
          })
          this.UPDATE_AUCTIONS_FETCHED_ARTICLE({
            auctionID: auctionId,
            article: data.data
          })
          this.articles = updatedArticles
        } else if (data.type === PushType.delete) {
          const updatedArticles = [...this.articles]
          const idx = updatedArticles.findIndex(el => el.id === data.data.id)
          updatedArticles.splice(idx, 1)
          this.REMOVE_AUCTIONS_FETCHED_ARTICLE({
            auctionID: auctionId,
            article: data.data
          })
          this.articles = updatedArticles
        } else if (data.type === PushType.create) {
          const updatedArticles = [...this.articles]
          updatedArticles.push(data.data)
          this.ADD_AUCTIONS_FETCHED_ARTICLE({
            auctionID: auctionId,
            article: data.data
          })
          this.articles = updatedArticles
        }
      } else if (data.entity === PushEntity.category) {
        if (data.type === PushType.update) {
          const updatedCategories = this.adminCategories.map(category => {
            if (category.id === data.data.id) {
              return {
                ...category,
                ...data.data
              }
            }
            return category
          })
          this.SET_ADMIN_CATEGORIES(updatedCategories)
          this.categories = updatedCategories
        } else if (data.type === PushType.delete) {
          const updatedCategories = [...this.adminCategories]
          const idx = updatedCategories.findIndex(el => el.id === data.data.id)
          updatedCategories.splice(idx, 1)
          this.SET_ADMIN_CATEGORIES(updatedCategories)
          this.categories = updatedCategories
        } else if (data.type === PushType.create) {
          const updatedCategories = [...this.adminCategories]
          updatedCategories.push(data.data)
          this.SET_ADMIN_CATEGORIES(updatedCategories)
          this.categories = updatedCategories
        }
      } else if (data.entity === PushEntity.user) {
        if (data.type === PushType.update) {
          const updatedUsers = this.users.map(user => {
            if (user.id === data.data.id) {
              return {
                ...user,
                ...data.data
              }
            }
            return user
          })
          this.SET_USERS(updatedUsers)
        } else if (data.type === PushType.delete) {
          const updatedUsers = [...this.users]
          const idx = updatedUsers.findIndex(el => el.id === data.data.id)
          updatedUsers.splice(idx, 1)
          this.SET_USERS(updatedUsers)
        } else if (data.type === PushType.create) {
          const updatedUsers = [...this.users]
          updatedUsers.push(data.data)
          this.SET_USERS(updatedUsers)
        }
      }
    }
  }
}
</script>
