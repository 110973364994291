<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <v-card class="user-profile-container user-catalogue pb-0">
      <div class="close-btn-wrapper" v-if="!modalPosition">
        <v-btn class="close-btn" @click="closeProfileModal()">
          <v-icon class="close-modal-icon">fa-times</v-icon>
        </v-btn>
        <v-btn class="close-btn sync mt-2 icon-with-hover" @click="syncData" :disabled="_.isEmpty(currentAuction)" v-if="selectedAuctionData">
          <v-icon class="close-modal-icon">fa-sync</v-icon>
        </v-btn>
      </div>
      <v-card-text class="main-card" v-if="auctions">
        <v-container>
          <div>
            <v-row align="center" class="form-container justify-center flex-column">
              <v-col sm="12" class="full-width pb-0">
                <h2 class="page-title text-center">{{ $t('My Bids') }}</h2>
                <v-col class="main-col-wrapper px-0" v-if="!preselectedAuction || localySelected">
                  <v-col align="center" class="sub-col-wrapper">
                    <v-row>
                      <v-col class="text-center" v-if="!auctions.length">
                        <div class="status__item status__item--error">
                          <div class="status__icon" />
                          <p class="status__text">{{ $t('There are no auctions to choose from') }}</p>
                        </div>
                      </v-col>
                      <search-box
                        v-else
                        :currentAuction="currentAuction"
                        :auctions="auctions"
                        hide-create-new
                        title=""
                        @onSelectAuction="getAuctionDetail"
                        show-auction-type
                      />
                    </v-row>
                  </v-col>
                </v-col>
              </v-col>
              <v-progress-circular v-if="loading" class="loading-icon mt-6" indeterminate />
              <v-col sm="12" v-else-if="!_.isEmpty(currentAuction) && !error && articles.length" class="pt-0 pb-16 pb-sm-0 px-0">
                <v-data-table
                  :headers="disabled ? headers.slice(0, headers.length - 1) : headers"
                  :items="articlesView()"
                  :items-per-page="itemPerPage"
                  class="knockdown-table pa-2 pb-16 pb-sm-2"
                  :class="{disabled}"
                  item-class="edit-article-row"
                  sort-by="number"
                  must-sort
                  ref="table"
                  :key="key"
                  @click:row="article => openArticleDetailedModal(article)"
                  dense
                  :footer-props="{
                    'items-per-page-all-text': $t('All'),
                    'items-per-page-text': $t('Rows per page'),
                    'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                    'items-per-page-options': [5, 10, 25, 50]
                  }"
                >
                  <template #[`item.number`]="{ item }">
                    <div class="d-flex align-center justify-space-between">
                      <p class="ma-0">{{ item.number_optional || item.number }}</p>
                      <v-icon class="icon-search-circle icon-with-hover" small>fa-search</v-icon>
                    </div>
                  </template>
                  <template #[`item.ends_at`]="{ item }">
                    <p class="ma-0">{{ getFormattedDate(item.timedData.active_until, appStoreLang, true, false) }}</p>
                  </template>
                  <template #[`item.status`]="{ item }">
                    <v-tooltip right v-if="item.status === 'pending'">
                      <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" style="font-weight: 400;">fa-hourglass</v-icon>
                      </template>
                      <span>{{ $t('Absentee bid pending') }}</span>
                    </v-tooltip>
                    <v-tooltip right v-if="item.status === 'accepted'">
                      <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="green-color-success">fa-check</v-icon>
                      </template>
                      <span>{{ $t('Absentee bid accepted') }}</span>
                    </v-tooltip>
                    <v-tooltip right v-if="item.status === 'declined'">
                      <template #activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="red-color">fa-times</v-icon>
                      </template>
                      <span>{{ $t('Absentee bid declined') }}</span>
                    </v-tooltip>
                  </template>
                  <template #[`item.commission_bid`]="{ item }">
                    <span class="text-bold">
                      {{ currency }}{{ euNumbers(item.commission_bid) }}
                    </span>
                  </template>
                  <template #[`item.name`]="{ item }">
                    <div class="article-name-col">
                      <div class="article-name">{{ articleTitle(item) }}</div>
                    </div>
                  </template>
                  <template #[`item.limit`]="{ item }">
                    <div class="article-price-col">
                      <div class="article-price">
                        {{ currency }} {{ euNumbers(item.limit) }}
                      </div>
                    </div>
                  </template>
                  <template #[`item.bidder_status`]="{ item }">
                    <p class="green--text ma-0" v-if="item.timedData.bidder_status === 'highest-bidder'">{{ $t('Highest bidder') }}</p>
                    <p class="green--text ma-0" v-else-if="item.timedData.bidder_status === 'bought'">{{ $t('Bought') }}</p>
                    <p class="green--text ma-0" v-else-if="item.timedData.bidder_status === 'bought-instant'">{{ $t('Bought instantly') }}</p>
                    <p class="red--text ma-0" v-else-if="item.timedData.bidder_status === 'outbid'">
                      {{ $t('Outbid') }}<br>
                      <v-btn class="primary-btn small" @click.stop="openTimedBidModal(item)">{{ $t('Bid again') }}</v-btn>
                    </p>
                    <p class="ma-0" v-else>{{ $t('No bids') }}</p>
                  </template>
                  <template #[`item.bid`]="{ item }">
                    <div class="article-price-col">
                      <div class="article-price" v-if="item.timedData.maximum_bid">
                        {{ currency }} {{ euNumbers(item.timedData.maximum_bid) }}
                      </div>
                    </div>
                  </template>
                  <template #[`item.current_hammer_price`]="{ item }">
                    <div class="article-price-col" v-if="item.timedData.current_hammer_price">
                      <div class="article-price">
                        {{ currency }} {{ euNumbers(item.timedData.current_hammer_price) }}
                      </div>
                    </div>
                  </template>
                  <template #[`item.pickup`]="{ item }">
                    <v-icon v-if="!item.pickup" class="red-color">fa-times</v-icon>
                    <v-icon v-else class="green-color-success">fa-check</v-icon>
                  </template>
                  <template #[`item.bid_by_telephone`]="{ item }">
                    <v-icon v-if="!item.bid_by_telephone" class="red-color">fa-times</v-icon>
                    <v-icon v-else class="green-color-success">fa-check</v-icon>
                  </template>
                  <template #top>
                    <v-row>
                      <v-col class="col-12 col-sm-2 pt-0">
                        <v-select
                          v-if="selectedAuctionData.type === 'timed'"
                          v-model="selectedStatus"
                          class="select-input"
                          append-icon="fa-angle-down"
                          @input="searchHandler"
                          hide-details
                          :items="statusItemsTimed"
                          :menu-props="{ bottom: true, offsetY: true }"
                          :label="$t('Type')"
                        />
                        <v-select
                          v-else
                          v-model="selectedStatus"
                          class="select-input"
                          append-icon="fa-angle-down"
                          @input="searchHandler"
                          hide-details
                          :items="statusItemsLive"
                          :menu-props="{ bottom: true, offsetY: true }"
                          :label="$t('Type')"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
              <v-col sm="12" class="text-center" v-else>
                <h3 v-if="_.isEmpty(currentAuction)" class="no-data-text">{{ $t('Please select an auction') }}</h3>
                <h3 v-else class="no-data-text">{{ $t('There are no bids for this auction') }}</h3>
              </v-col>
              <!--<v-col sm="12" class="text-center" v-else>
                <h3 class="no-data-text">{{ $t(error) }}</h3>
              </v-col>-->
              <v-col class="text-center" v-if="getAuctionError">
                <div class="status__item status__item--error">
                  <div class="status__icon" />
                  <p class="status__text">{{ $t(`This auction doesn't exist`) }}</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <timed-bid-modal
      :auction="selectedAuctionData"
      :article="selectedArticle"
      @updateArticle="updateArticle"
      ref="timedBidModal"
    />
    <article-details-modal
      ref="articleDetailsModal"
      :article="selectedDetailedArticle"
      :selected-auction-data="selectedAuctionData"
      :prev-article="prevArticle"
      :next-article="nextArticle"
      @openArticleDetailedModal="openArticleDetailedModal"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'pinia'
import SearchBox from '../settings/articles/sections/search'
import dispatcher from '@/api/dispatch';
import TimedBidModal from '@/components/modals/user-timed-bid-modal'
import articleDetailsModal from '@/components/modals/article-details-modal'
import articleDetails from '@/mixins/article-details'
import moment from 'moment';
import useRootStore from '@/stores/rootStore'
import { euNumbers, getFormattedDate, getTranslatedText } from '@/services/i18n';
import { AuctionFlavor } from '@/utils/constants';

export default {
  name: 'KnockdownsModal',
  components: {
    SearchBox,
    TimedBidModal,
    articleDetailsModal
  },
  data: function () {
    return {
      renderComponent: true,
      showProfileModal: false,
      modalPosition: false,
      profileLocalization: {},
      currentAuction: {},
      search: '',
      getAuctionError: false,
      headers: [],
      itemPerPage: 10,
      page: 0,
      start: 0,
      end: 0,
      key: 0,
      error: '',
      tmpArticles: [],
      loading: false,
      searchResult: [],
      showKnockdownsModal: true,
      auctions: null,
      defaultAuctions: null,
      selectedAuctionData: null,
      selectedArticle: {},
      statusItemsTimed: [
        {
          text: this.$t('All'),
          value: 'all'
        },
        {
          text: this.$t('Highest bidder'),
          value: 'highest-bidder'
        },
        {
          text: this.$t('Outbid'),
          value: 'outbid'
        },
        {
          text: this.$t('Bought'),
          value: 'bought'
        },
        {
          text: this.$t('Bought instantly'),
          value: 'bought-instant'
        },
      ],
      statusItemsLive: [
        {
          text: this.$t('All'),
          value: 'all'
        },
        {
          text: this.$t('Pending'),
          value: 'pending'
        },
        {
          text: this.$t('Accepted'),
          value: 'accepted'
        },
        {
          text: this.$t('Declined'),
          value: 'declined'
        },
      ],
      selectedStatus: 'all',
      allArticles: null,
      localySelected: true
    }
  },
  mixins: [
    articleDetails
  ],
  computed: {
    ...mapState(useRootStore, ['userProfile', 'appLocalization', 'appSettings', 'appStoreLang', 'auctionsFetchedArticles', 'preselectionData']),
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    disabled () {
      return !this.currentAuction
    },
    currency () {
      return this.appLocalization.currency
    },
    preselectedAuction() {
      return this.$route.params.id
    }
  },
  async created() {
    this.setAuctionDefaults()
    await this.getAuctions()
    if (this.preselectedAuction) {
      this.localySelected = false
      this.currentAuction = this.auctions.find(el => el.code.toString() === this.preselectedAuction.toString())
      if (this.currentAuction) {
        await this.getAuctionDetail(this.currentAuction.id)
      } else {
        this.getAuctionError = true
      }
    }
    this.checkUrl()
  },
  async mounted() {
    // every time the view is shown i.e. on a route change
    // if no auction is preselected via the url and there is one preselected in the store, use this
    if (this.preselectionData.selectedAuctionId) {
      this.search = null
      await this.getAuctions()
      const targetAuction = this.auctions.find(el => el.id === this.preselectionData.selectedAuctionId);
      this.selectedAuctionData = targetAuction
      this.currentAuction = targetAuction
      if (this.selectedAuctionData && this.selectedAuctionData.code !== this.$route.params.id) this.$router.push({ params: { id: this.selectedAuctionData.code } })
      await this.getAuctionDetail(this.selectedAuctionData.id)
    }
  },
  watch: {
    appStoreLang() {
      this.$nextTick(() => {
        if (this.selectedAuctionData) {
          if (this.selectedAuctionData.type === 'timed') {
            this.setTimedAuctionDefaults()
          } else {
            this.setAuctionDefaults()
          }
        }
      });
    },
    auctionsFetchedArticles: {
      deep: true,
      handler(val) {
        let articles = val[`auction_${this.currentAuction.id}`]
        if (!articles) return

        const updatedArticles = articles.map(el => {
          const prevVal = this.articles.find(article => article.id === el.id)
          return {
            ...prevVal,
            ...el
          }
        })
        this.articles = updatedArticles
        this.tmpArticles = updatedArticles

        this.key++
        if (this.categories && this.categories.length) {
          for (let category in this.categories) {
            if (!this.categories[category].articlesCount) {
              this.categories[category] = {
                ...this.categories[category],
                articlesCount: 0
              }
            }
            this.categories[category].articlesCount = this.articles.filter(el => el.category_id === this.categories[category].id).length
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile', 'getArticles']),
    euNumbers,
    getFormattedDate,
    changePage (el) {
      this.page = el
      this.$refs.table.$el.scrollIntoView({ behavior: 'smooth' })
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    // Close profile modal without saving
    closeProfileModal () {
      this.$router.push({ name: 'dashboard' })
    },

    async getAuctionDetail(auctionID, getArticles = true) {
      try {
        this.loading = true
        this.selectedAuctionData = this.auctions.find(el => el.id === auctionID)
        if (!this.$route.params.id) this.localySelected = true
        const auction = this.auctions.find(el => el.id.toString() === auctionID.toString())
        if (auction && this.$route.params.id !== auction.code) await this.$router.push({ params: { id: auction.code } })
        this.page = 0
        if (this.selectedAuctionData.type === AuctionFlavor.timed) {
          this.setTimedAuctionDefaults()
          if (getArticles) {
            await this.getArticles({auctionID, auction: this.selectedAuctionData})
            this.allArticles = this.auctionsFetchedArticles[`auction_${auctionID}`]
          }
          let articles = []
          const result = await dispatcher.getArticleTimedStatus(auctionID, "all")

          result.timedBids.forEach(el => {
            this.allArticles.forEach(article => {
              if (el.article_id === article.id) {
                articles.push({
                  ...article,
                  timedBidData: el
                })
              }
            })
          })
          this.currentAuction.id = auctionID
          const filteredArticles = articles.filter(el => el.timedData && el.timedData.bidder_status !== 'no-bid').sort((a, b) => a.number - b.number)
          this.articles = filteredArticles
          this.tmpArticles = filteredArticles
        } else { //live auction
          this.setAuctionDefaults()
          await this.getArticles({ auctionID, auction: this.selectedAuctionData })
          let response;
          try {
            response = await dispatcher.getAllCommissionBids(auctionID, false)
          } catch (e) {
            this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the commission bids. Please try again later') })
            this.error = this.$t('There was an error getting the commission bids. Please try again later\'')
            this.loading = false
            return
          }

          const articles = response.commission_bids.map(el => {
            const article = this.auctionsFetchedArticles[`auction_${auctionID}`].find(item => item.id === el.articles_id)
            return {
              ...article,
              ...el
            }
          })

          const sorted = [...articles.sort((a, b) => {
            if (a.number && b.number) {
              return a.number - b.number
            } else {
              return a.number_optional - b.number_optional
            }
          })]

          this.currentAuction.id = auctionID
          this.articles = sorted
          this.tmpArticles = sorted
        }
        this.error = null
        this.key++
      } catch (e) {
        if (e.data?.error) {
          this.error = this.$t(e.data.data[0].errorShortText)
        } else {
          throw e
        }
      } finally {
        this.loading = false
      }
    },
    articlesView() {
      if (this.search) {
        this.searchResult = []
        const tableSort = [...this.headers]
        tableSort.splice(this.headers.length - 1, 1)
        this.tmpArticles.forEach(el => {
          tableSort.forEach(val => {
            if (el[val.value] && el[val.value].toString().toLowerCase().includes(this.search.toLowerCase()) && this.searchResult.map(({ id }) => id).indexOf(el.id) === -1) {
              this.searchResult.push(el)
            }
          })
        })
        return this.searchResult;
      } else {
        return this.tmpArticles;
      }
    },
    searchHandler () {
      if (this.search || (this.selectedStatus && this.selectedStatus !== 'all')) {
        this.tmpArticles = this.articles.filter(el => el.number?.toString().includes(this.search) || el.number_optional?.includes(this.search) || el.name?.toLowerCase().includes(this.search.toLowerCase()))
        if (this.selectedStatus && this.selectedStatus !== 'all') {
          this.tmpArticles = this.tmpArticles.filter(el => (el.timedData && el.timedData.bidder_status === this.selectedStatus) || el.status === this.selectedStatus)
        }
        this.tmpArticles.sort((a, b) => a.number - b.number)
      } else {
        this.tmpArticles = this.articles.sort((a, b) => a.number - b.number)
      }
    },
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },

    /**
     * Get all auctions from api and filter according to conditionals
     * @return {any} - Modified auctions
     */
    async getAuctions() {
      try {
        const data = await dispatcher.getAllAuctions();
        this.auctions = data.filter(el => {
          return el.type === 'timed' ? moment(el.active_until).diff(moment(), 'seconds') > 0 && el.catalogue_enabled !== 'disabled' : moment(el.active_until).diff(this.moment(), 'seconds') > 0 && el.catalogue_enabled !== 'disabled' && el.commission_bids_enabled
        })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },

    changePerPage(item) {
      this.itemPerPage = item
      this.page = 0
    },

    setAuctionDefaults() {
      this.headers = [
        {
          text: this.$t('Article #'),
          value: 'number',
          sortable: true,
          width: 150
        },
        {
          text: this.$t('Status'),
          value: 'status',
          align: 'left',
          sortable: false,
          width: 130
        },
        {
          text: this.$t('Lot name'),
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('Limit'),
          value: 'limit',
          align: 'left',
          sortable: false,
          width: 100
        },
        {
          text: this.$t('Absentee bid'),
          value: 'commission_bid',
          align: 'left',
          sortable: true,
          width: 110
        },
        {
          text: this.$t('Pickup'),
          value: 'pickup',
          align: 'left',
          sortable: true,
          width: 110
        },
        {
          text: this.$t('Bid by telephone'),
          value: 'bid_by_telephone',
          align: 'left',
          sortable: true,
          width: 110
        },
      ]
    },
    setTimedAuctionDefaults() {
      this.headers = [
        {
          text: this.$t('Article #'),
          value: 'number',
          width: 150
        },
        {
          text: this.$t('Status'),
          value: 'bidder_status',
          align: 'left',
          width: 130
        },
        {
          text: this.$t('Ends at'),
          value: 'ends_at',
          align: 'left',
          width: 220
        },
        {
          text: this.$t('Lot name'),
          value: 'name',
          align: 'left',
        },
        {
          text: this.$t('Limit'),
          value: 'limit',
          align: 'left',
          width: 100
        },
        {
          text: this.$t('Your bid'),
          value: 'bid',
          align: 'left',
          width: 150
        },
        {
          text: this.$t('Current hammer price'),
          value: 'current_hammer_price',
          align: 'left',
          width: 140
        }
      ]
    },
    openTimedBidModal(article) {
      this.selectedArticle = { ...article }
      this.$refs.timedBidModal.openModal()
    },
    updateArticle(data) {
      this.selectedArticle.timedBidData = data
      this.key++
    },

    syncData() {
      this.getAuctionDetail(this.currentAuction.id, false)
    }
  }
}
</script>
<style lang="scss" scoped>
.knockdown-table {
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
}
.article-name {
  font-size: 12px;
  -webkit-line-clamp: 1;
  @media (max-width: 968px) {
    white-space: pre-wrap;
    -webkit-line-clamp: unset;
  }
}
</style>
