import { defaultRoute, Role } from '@/utils/constants'
import _ from 'lodash'
import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/login/index.vue'
import confirmationUser from '@/views/registration/confirmation.vue'
import auction from '@/views/auction/index.vue'
import auctionViewer from '@/views/auction-viewer/index.vue'
import auctionAdmin from '@/views/auction-admin/index.vue'
import auctionAuctioneer from '@/views/auction-auctioneer/index.vue'
import adminSettings from '@/views/settings/index.vue'
import auctionsSetting from '@/views/settings/auctions/index.vue'
import generalSetting from '@/views/settings/general/index.vue'
import articlesSetting from '@/views/settings/articles/index.vue'
import CommissionBids from '@/views/settings/commission_bids/index.vue'
import categoriesSetting from '@/views/settings/categories/index.vue'
import mailingSetting from '@/views/settings/mailling/index.vue'
import inquiriesSetting from '@/views/settings/inquiries/index.vue'
import systemSetting from '@/views/settings/system/index.vue'
import usersSetting from '@/views/settings/users/index.vue'
import Streaming from '@/views/streaming/index.vue'
import Logs from '@/views/settings/logs/index.vue'
import Infos from '@/views/info/index.vue'
import Imprint from '@/views/info/imprint.vue'
import UserProfile from '@/views/profile-user/index.vue'
import UserCatalogue from '@/views/product-catalogue-user/index.vue'
import PastAuctions from '@/views/past-auctions/index.vue'
import UserPostAuctionSale from '@/views/post-auction-sale-user/index.vue'
import UserKnockdowns from '@/views/knockdowns-user/index.vue'
import UserBids from '@/views/bids-user/index.vue'
import UserWishList from '@/views/wish-list-user/index.vue'
import UserHighlights from '@/views/highlights-user/index.vue'
import BidTransactions from '@/views/settings/bid-transactions-user/index.vue'
import Statistics from '@/views/statistics/index.vue'
import EmailsStatus from '@/views/settings/emails-status/index.vue'
import AdminUserProfile from '@/views/settings/users/user-profile.vue'
import Search from '@/views/search/index.vue'
import NotFoundPage from '@/views/error/NotFoundPage.vue'
import UnexpectedPage from '@/views/error/UnexpectedPage.vue'
import useRootStore from '@/stores/rootStore';
import { getAuthToken, isInvalidToken } from '@/utils/token';
import AuctionGuest from '@/views/auction-guest/index.vue';
import dispatcher from '@/api/dispatch';

Vue.use(VueRouter);
export const routes = [
  {
    path: '/',
    name: 'home',
    component: login,
    meta: {
      requiresAuth: false,
      plainLayout: true,
      authorize: []
    }
  },
  {
    path: '/socialLoginCallback',
    name: 'socialLoginCallback',
    redirect: '/'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: auction,
    meta: {
      requiresAuth: true,
      checkStoreLoggedUser: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/auction-viewer',
    name: 'auctionViewer',
    component: auctionViewer,
    meta: {
      requiresAuth: true,
      authorize: [Role.viewer]
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/catalogue/:id/:article?/:articleNumber?',
    name: 'catalogue',
    component: UserCatalogue,
    meta: {
      requiresAuth: true,
      checkStoreLoggedUser: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/past-auctions/:id/:article?/:articleNumber?',
    name: 'pastAuctions',
    component: PastAuctions,
    meta: {
      requiresAuth: true,
      checkStoreLoggedUser: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/post-auction-sale/:id/:article?/:articleNumber?',
    name: 'postAuctionSale',
    component: UserPostAuctionSale,
    meta: {
      requiresAuth: true,
      checkStoreLoggedUser: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/knockdowns/:id?/:article?/:articleNumber?',
    name: 'knockdowns',
    component: UserKnockdowns,
    meta: {
      requiresAuth: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/bids/:id?/:article?/:articleNumber?',
    name: 'bids',
    component: UserBids,
    meta: {
      requiresAuth: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/wishlist/:id?',
    name: 'wishlist',
    component: UserWishList,
    meta: {
      requiresAuth: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/highlights/:id',
    name: 'highlights',
    component: UserHighlights,
    meta: {
      requiresAuth: true,
      checkStoreLoggedUser: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/auctioneers-screen',
    name: 'auctioneer',
    component: auctionAuctioneer,
    meta: {
      requiresAuth: true,
      authorize: [Role.auctioneer]
    }
  },
  {
    path: '/auction-admin',
    name: 'auctionAdmin',
    component: auctionAdmin,
    meta: {
      requiresAuth: true,
      authorize: [Role.admin]
    }
  },
  {
    path: '/streaming',
    name: 'streaming',
    component: Streaming,
    meta: {
      requiresAuth: true,
      authorize: [Role.admin, Role.streamer]
    }
  },
  {
    path: '/search',
    name: 'search',
    component: Search,
    meta: {
      requiresAuth: true,
      checkStoreLoggedUser: true,
      authorize: [Role.user]
    }
  },
  {
    path: '/settings',
    // name: 'adminSettings',
    component: adminSettings,
    meta: {
      requiresAuth: true,
      authorize: [Role.admin]
    },
    children: [
      {
        path: '',
        redirect: 'auctions'
      },
      {
        path: 'auctions',
        name: 'Auctions',
        component: auctionsSetting,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'articles/:id',
        name: 'Articles',
        component: articlesSetting,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'general/:id',
        name: 'General',
        component: generalSetting,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'commission_bids/:id?',
        name: 'CommissionBids',
        component: CommissionBids,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'categories',
        component: categoriesSetting,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'system',
        component: systemSetting,
        name: 'SystemSetting',
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'mailing',
        component: mailingSetting,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'inquiries',
        name: 'inquiries',
        component: inquiriesSetting,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: usersSetting,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'user/:id?',
        name: 'AdminUserProfile',
        component: AdminUserProfile,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'bid-transactions',
        name: 'bidTransactions',
        component: BidTransactions,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'statistics',
        name: 'statistics',
        component: Statistics,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'emails',
        name: 'emails',
        component: EmailsStatus,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
      {
        path: 'logs',
        component: Logs,
        meta: {
          requiresAuth: true,
          authorize: [Role.admin],
          isChild: true,
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      requiresAuth: false,
      plainLayout: true
    }
  },
  {
    path: '/registration',
    name: 'registration',
    component: login,
    meta: {
      requiresAuth: false,
      plainLayout: true
    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: login,
    meta: {
      requiresAuth: false,
      plainLayout: true
    }
  },
  {
    path: '/resendEmail',
    name: 'resendEmail',
    component: login,
    meta: {
      requiresAuth: false,
      plainLayout: true
    }
  },
  {
    path: '/reset-new-password',
    name: 'resetNewPassword',
    component: login,
    meta: {
      requiresAuth: false,
      plainLayout: true
    }
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    component: confirmationUser,
    meta: {
      requiresAuth: false,
      plainLayout: true
    }
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: Imprint,
    meta: {
      requiresAuth: false,
      // plainLayout: true
    }
  },
  {
    path: '/infos',
    name: 'infos',
    component: Infos,
    meta: {
      requiresAuth: false,
      // plainLayout: true
    }
  },
  {
    path: '*',
    name: 'error',
    component: NotFoundPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/error',
    name: 'error-unexpected',
    component: UnexpectedPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/guest-live-auction',
    name: 'guestLiveAuction',
    component: AuctionGuest,
    meta: {
      requiresAuth: false,
      // plainLayout: true
    }
  },
]

export const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
});
router.beforeEach(async (to, from, next) => {
  const rootStore = useRootStore()
  let token = getAuthToken();
  // If component require authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.matched[0].meta.checkStoreLoggedUser) {
      if (rootStore.appFeatures?.guestFunctionality) {
        next()
        return
      } else if (!_.isEmpty(rootStore.appFeatures) && !rootStore.appFeatures.guestFunctionality) {
        next()
      } else if (!token) {
        const result = await dispatcher.getAppSettings()
        if (result.features?.guestFunctionality) {
          next()
          rootStore.UPDATE_GLOBAL_STATE({ key: 'appSettings', value: result.general })
          rootStore.UPDATE_GLOBAL_STATE({ key: 'appFeatures', value: result.features })
          return
        }
      }
    }
    // but token is invalid
    if (isInvalidToken(token)) {
      // Back to login
      next({
        path: '/login',
        query: { nextUrl: to.fullPath }
      })
    // check if the user role is authorized
    } else {
      const { userProfile } = rootStore;
      const { authorize } = to.meta;
      if (authorize && authorize?.indexOf(userProfile.role) >= 0) {
        next();
      } else {
        // TODO: check this It's working ???
        next({ path: defaultRoute[userProfile.role] });
      }
    }
  } else {
    // Continue with current page
    next()
  }
});

export default router
