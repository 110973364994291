import mitt from 'mitt'
import { PushEntity, PushType, Role, SocketNamespace } from '@/utils/constants';
import router from '@/router';
import {
  IAskSocketHandlerData, IChangeArticleSocketHandlerData,
  IUndoBidSocketHandlerData,
  IUpdateBidSocketHandlerData
} from '@/api/dto';
import useAuctionStore from '@/stores/auctionStore';
import useRootStore from '@/stores/rootStore';
import _ from 'lodash';
import { i18n } from '@/main';

enum SocketEvent {
  updateBid = 'updateBid',
  pushRealtimeUsersData = 'pushRealtimeUsersData',
  startAuction = 'startAuction',
  pauseAuction = 'pause',
  resumeAuction = 'resume',
  stopAuction = 'stop',
  endAuction = 'end',
  pushDataUpdate = 'pushDataUpdate',
  networkPause = 'networkPause',
  logoutAdmin = 'logoutAdmin',
  bidWarning = 'bidWarning',
  ask = 'ask',
  undoBid = 'undo',
  changeArticle = 'changeArticle',
  sellItem = 'sellItem'
}

export enum SocketHandleEmitterEvent {
  updateBidPreHandleEmitterEvent = 'updateBidPreHandleEmitterEvent',
  updateBidPostHandleEmitterEvent = 'updateBidPostHandleEmitterEvent',
  clientListPretHandleEmitterEvent = 'clientListPreHandleEmitterEvent',
  clientListPostHandleEmitterEvent = 'clientListPostHandleEmitterEvent',
  startAuctionPreHandleEmitterEvent = 'startAuctionPretHandleEmitterEvent',
  startAuctionPostHandleEmitterEvent = 'startAuctionPostHandleEmitterEvent',
  pauseAuctionPreHandleEmitterEvent = 'pauseAuctionPreHandleEmitterEvent',
  pauseAuctionPostHandleEmitterEvent = 'pauseAuctionPostHandleEmitterEvent',
  resumeAuctionPreHandleEmitterEvent = 'resumeAuctionPreHandleEmitterEvent',
  resumeAuctionPostHandleEmitterEvent = 'resumeAuctionPostHandleEmitterEvent',
  stopAuctionPreHandleEmitterEvent = 'stopAuctionPreHandleEmitterEvent',
  stopAuctionPostHandleEmitterEvent = 'stopAuctionPostHandleEmitterEvent',
  endAuctionPreHandleEmitterEvent = 'endAuctionPreHandleEmitterEvent',
  endAuctionPostHandleEmitterEvent = 'endAuctionPostHandleEmitterEvent',
  pushDataUpdatePreHandleEmitterEvent = 'pushDataUpdatePreHandleEmitterEvent',
  pushDataUpdatePostHandleEmitterEvent = 'pushDataUpdatePostHandleEmitterEvent',
  networkPausePreHandleEmitterEvent = 'networkPausePreHandleEmitterEvent',
  networkPausePostHandleEmitterEvent = 'networkPausePostHandleEmitterEvent',
  logoutAdminPreHandleEmitterEvent = 'logoutAdminPreHandleEmitterEvent',
  logoutAdminPostHandleEmitterEvent = 'logoutAdminPostHandleEmitterEvent',
  bidWarningPreHandleEmitterEvent = 'bidWarningPreHandleEmitterEvent',
  bidWarningPostHandleEmitterEvent = 'bidWarningPostHandleEmitterEvent',
  askPreHandleEmitterEvent = 'askPreHandleEmitterEvent',
  askPostHandleEmitterEvent = 'askPostHandleEmitterEvent',
  undoBidPreHandleEmitterEvent = 'undoBidPreHandleEmitterEvent',
  undoBidPostHandleEmitterEvent = 'undoBidPostHandleEmitterEvent',
  changeArticlePreHandleEmitterEvent = 'changeArticlePreHandleEmitterEvent',
  changeArticlePostHandleEmitterEvent = 'changeArticlePostHandleEmitterEvent',
  sellItemPreHandleEmitterEvent = 'sellItemPreHandleEmitterEvent',
  sellItemPostHandleEmitterEvent = 'sellItemPostHandleEmitterEvent',

}

interface ISocketHandlerEntry
{
  socketEvent: SocketEvent,
  roles: Role[],
  routes: string[]
}

const socketHandlerEntries: ISocketHandlerEntry[] =
[
  {
    socketEvent: SocketEvent.updateBid,
    roles: [Role.guest, Role.user, Role.admin, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctionAdmin', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.pushRealtimeUsersData,
    roles: [Role.admin],
    routes: ['*']
  },
  {
    socketEvent: SocketEvent.startAuction,
    roles: [Role.guest, Role.user, Role.admin, Role.viewer, Role.auctioneer],
    routes: ['*']
  },
  {
    socketEvent: SocketEvent.resumeAuction,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['*']
  },
  {
    socketEvent: SocketEvent.pauseAuction,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.stopAuction,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.endAuction,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.networkPause,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.logoutAdmin,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.bidWarning,
    roles: [Role.guest, Role.user],
    routes: ['dashboard', 'auction', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.ask,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.undoBid,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.changeArticle,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
  {
    socketEvent: SocketEvent.sellItem,
    roles: [Role.guest, Role.user, Role.viewer, Role.auctioneer],
    routes: ['dashboard', 'auction', 'auctioneer', 'auctionViewer', 'guestLiveAuction']
  },
]

export default class SocketHandlers {
  private static emitter: any;

  /**
   * Check authorisation (this is if the role AND the current route matches)
   * @param {ISocketHandlerEntry} socketHandlerEntry - The socket handler entry
   * @return {boolean} - True if authorisation is granted, false otherwise
   */
  private static checkHandlerRole(socketEvent: SocketEvent): boolean {
    const rootStore = useRootStore();
    const handlers = socketHandlerEntries.filter(e => e.socketEvent === socketEvent);
    if (handlers.length !== 1) {
      console.log(`socket event '${socketEvent}' is not in the socket handler table'`);
      return false;
    }

    const socketHandlerEntry = handlers[0];
    if (_.isEmpty(rootStore.userProfile)) {
      if (!socketHandlerEntry.roles.includes(Role.guest)) {
        console.log(`current user role 'guest' is not in the list of requires roles for the socket event '${socketHandlerEntry.socketEvent}'`);
        return false;
      }
    } else {
      if (!socketHandlerEntry.roles.includes(rootStore.userProfile.role)) {
        console.log(`current user role '${rootStore.userProfile.role}' is not in the list of requires roles for the socket event '${socketHandlerEntry.socketEvent}'`);
        return false;
      }
    }

    if (!socketHandlerEntry.routes.includes(router.currentRoute.name) && !socketHandlerEntry.routes.includes('*')) {
      console.log(`current route '${router.currentRoute.name}' is not in the list of requires routes for the socket event '${socketHandlerEntry.socketEvent}'`);
      return false;
    }
    return true
  }

  /**
   * Initialize the mitt emitter
   * @return {any} - The emitter
   */
  public static getEmitter(): any {
    if (SocketHandlers.emitter === undefined) {
      SocketHandlers.emitter = mitt()
    }

    return SocketHandlers.emitter;
  }

  /**
   * Load all socket handlers for a namespace
   * @param {SocketNamespace} namespace - The socket namespace
   * @param {any} socket - The socket instance
   * @return {void} - Nothing
   */
  public static loadHandlers(namespace: SocketNamespace, socket: any): void {
    if (namespace === SocketNamespace.users || namespace === SocketNamespace.guests) {
      SocketHandlers.loadUserHandlers(socket)
    } else if (namespace === SocketNamespace.admins) {
      SocketHandlers.loadAdminHandlers(socket)
    }
    SocketHandlers.loadCommonHandlers(socket)
  }

  /**
   * Load all common socket handlers for the user namespace
   * @param {any} socket - The socket instance
   * @return {void} - Nothing
   */
  private static loadCommonHandlers(socket: any): void {
    const rootStore = useRootStore();
    const emitter = SocketHandlers.getEmitter();
    const userRole = rootStore.userProfile.role;

    socket.on(SocketEvent.pushDataUpdate, (data: any) => {
      // skip everything which is not a message on the auction view as user or guest
      if ((router.currentRoute.name === 'dashboard' || router.currentRoute.name === 'guestLiveAuction') && data.entity !== 'message') {
        console.log('skipping real-time data update since the user is on the auction')
        return
      } else { // other routes
        emitter.emit(SocketHandleEmitterEvent.pushDataUpdatePreHandleEmitterEvent, data);
        console.log('socketHandler.ts')
        console.log('Event pushDataUpdate', data)
        console.log(`received real-time data update, user: ${data.updated_by}, entity: ${data.entity}, type: ${data.type}`)
        console.log('payload:', data.data);

        if (userRole === Role.admin || rootStore.appFeatures.webSocketDataPush) {
          if (data.entity === PushEntity.message) {
            // don't send the reauction message if the user or guest is not on the auction view
            if ((router.currentRoute.name !== 'dashboard' && router.currentRoute.name !== 'guestLiveAuction') && data.data.content === 'toTranslate.article.reauctioned') return
            if (data.data.content.startsWith("toTranslate")) {
              console.log('nogger')
              data.data.content = i18n.t('toTranslate.article.reauctioned');
            }
            rootStore.SET_TEMP_ALERT(data.data)
          } else if (data.entity !== PushEntity.message && userRole === Role.admin && rootStore.appFeatures.webSocketDataPush) {
            rootStore.SET_ADMIN_DATA_CHANGE(data)
          }
        }

        // for all roles
        if (rootStore.appFeatures.webSocketDataPush) {
          if (data.entity === PushEntity.article) {
            if (data.type === PushType.update) {
              rootStore.UPDATE_AUCTIONS_FETCHED_ARTICLE({
                auctionID: data.data.auction_id,
                article: data.data
              })
            } else if (data.type === PushType.delete) {
              rootStore.REMOVE_AUCTIONS_FETCHED_ARTICLE({
                auctionID: data.data.auction_id,
                article: data.data
              })
            } else if (data.type === PushType.create) {
              rootStore.ADD_AUCTIONS_FETCHED_ARTICLE({
                auctionID: data.data.auction_id,
                article: data.data
              })
            }
          } else if (data.entity === PushEntity.category && rootStore.categories) {
            if (data.type === PushType.update) {
              const categories = rootStore.categories.map(el => {
                if (el.id === data.data.id) return data.data
                return el
              })
              rootStore.SET_CATEGORIES(categories)
            } else if (data.type === PushType.delete) {
              rootStore.REMOVE_CATEGORY(data.data)
            } else if (data.type === PushType.create) {
              rootStore.ADD_CATEGORY(data.data)
            }
          }
        }
      }
      emitter.emit(SocketHandleEmitterEvent.pushDataUpdatePostHandleEmitterEvent, data);
    })
  }

  /**
   * Load all socket handlers for the user namespace
   * @param {any} socket - The socket instance
   * @return {void} - Nothing
   */
  private static loadUserHandlers(socket: any): void {
    const rootStore = useRootStore();
    const auctionStore = useAuctionStore()
    const emitter = SocketHandlers.getEmitter();
    const userRole = rootStore.userProfile.role;

    socket.on(SocketEvent.updateBid, (data: IUpdateBidSocketHandlerData) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.updateBid)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): new bid received (value ${data.highestBid.value})`)
      const newPayload = {
        ...auctionStore.currentAuction,
        highestBid: { ...data.highestBid },
        bidStep: { ...data.bidStep },
        nextBid: data.nextBid !== undefined ? { ...data.nextBid} : null
      }
      auctionStore.CHANGE_STATE({
        key: 'currentAuction',
        value: newPayload
      })
      auctionStore.CHANGE_STATE({
        key: 'loadingModal',
        value: false
      })
      emitter.emit(SocketHandleEmitterEvent.updateBidPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.startAuction, async (data: any) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.startAuction)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): auction start`)
      if (!data) {
        await auctionStore.getCurrentActiveAuction()
      } else {
        auctionStore.CHANGE_STATE({
          key: 'currentAuction',
          value: data
        })
      }
      emitter.emit(SocketHandleEmitterEvent.startAuctionPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.pauseAuction, async (data: any) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.pauseAuction)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): auction pause`)
      auctionStore.CHANGE_STATE([
        {
          key: 'currentAuction',
          value: {
            ...auctionStore.currentAuction,
            status: 'paused'
          }
        },
        {
          key: 'loadingModal',
          value: true,
        },
        {
          key: 'loadingType',
          value: 'paused',
        }
      ])
      emitter.emit(SocketHandleEmitterEvent.pauseAuctionPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.resumeAuction, async (data: any) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.resumeAuction)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): auction resume`)
      await auctionStore.getCurrentActiveAuction()
      auctionStore.CHANGE_STATE([
        {
          key: 'loadingModal',
          value: false,
        },
        { // TODO duplicate for auction view
          key: 'loadingType',
          value: 'resumed',
        }
      ])
      emitter.emit(SocketHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.stopAuction, async (data: any) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.stopAuction)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): auction stop`)
      emitter.emit(SocketHandleEmitterEvent.stopAuctionPreHandleEmitterEvent, data);
      await auctionStore.getCurrentActiveAuction();
      auctionStore.CHANGE_STATE([
        {
          key: 'currentAuction',
          value: {}
        },
        {
          key: 'currentAuctionArticles',
          value: []
        },
        {
          key: 'loadingModal',
          value: true,
        },
        {
          key: 'loadingType',
          value: 'noLive',
        }
      ])
      emitter.emit(SocketHandleEmitterEvent.stopAuctionPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.endAuction, async (data: any) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.endAuction)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): auction end`)
      emitter.emit(SocketHandleEmitterEvent.endAuctionPreHandleEmitterEvent, data);
      await auctionStore.getCurrentActiveAuction();
      auctionStore.CHANGE_STATE([
        {
          key: 'currentAuction',
          value: {}
        },
        {
          key: 'currentAuctionArticles',
          value: []
        },
        {
          key: 'loadingModal',
          value: true,
        },
        {
          key: 'loadingType',
          value: 'endAuction',
        }
      ])
      emitter.emit(SocketHandleEmitterEvent.endAuctionPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.networkPause, async (data: any) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.networkPause)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): network issues on auctioneers side`)
      await auctionStore.getCurrentActiveAuction();
      if (auctionStore.isAdminLogout) return;
      auctionStore.CHANGE_STATE([
        {
          key: 'loadingModal',
          value: true,
        },
        {
          key: 'loadingType',
          value: 'networkPause',
        }
      ])
      emitter.emit(SocketHandleEmitterEvent.networkPausePostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.logoutAdmin, async (data: any) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.logoutAdmin)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): admin logged out on auctioneers side`)
      auctionStore.SET_IS_ADMIN_LOGOUT(true);
      auctionStore.CHANGE_STATE([
        {
          key: 'loadingModal',
          value: true,
        },
        {
          key: 'loadingType',
          value: 'noLive',
        }
      ])
      emitter.emit(SocketHandleEmitterEvent.logoutAdminPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.bidWarning, async (data: any) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.bidWarning)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): auction article bid warning`)
      emitter.emit(SocketHandleEmitterEvent.bidWarningPreHandleEmitterEvent, data);
      auctionStore.CHANGE_STATE([
        {
          key: 'showPushNotiTooltip',
          value: true
        }
      ])
      setTimeout(() => {
        auctionStore.CHANGE_STATE({
          key: 'showPushNotiTooltip',
          value: false,
        })
      }, 5000)
      emitter.emit(SocketHandleEmitterEvent.bidWarningPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.ask, async (data: IAskSocketHandlerData) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.ask)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): auction article ask price update (value ${data.value})`)
      emitter.emit(SocketHandleEmitterEvent.askPreHandleEmitterEvent, data);
      auctionStore.CHANGE_STATE({
        key: 'currentAuction',
        value: {
          ...auctionStore.currentAuction,
          ongoingArticle: {
            ...auctionStore.currentAuction.ongoingArticle,
            last_ask_price: data.value,
          },
          nextBid: data.nextBid !== undefined ? { ...data.nextBid} : null
        }
      })
      emitter.emit(SocketHandleEmitterEvent.askPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.undoBid, async (data: IUndoBidSocketHandlerData) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.undoBid)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): undo last bid`)
      emitter.emit(SocketHandleEmitterEvent.undoBidPreHandleEmitterEvent, data);
      if (rootStore.appFeatures.webSocketAuctionStateManagement) {
        const newPayload = {
          ...auctionStore.currentAuction,
          ongoingArticle: {
            ...auctionStore.currentAuction.ongoingArticle,
            last_ask_price: data.ongoingArticle.last_ask_price
          },
          highestBid: { ...data.highestBid },
          bidStep: { ...data.bidStep },
          nextBid: data.nextBid !== undefined ? { ...data.nextBid} : null
        }
        auctionStore.CHANGE_STATE({
          key: 'currentAuction',
          value: newPayload
        })
      } else {
        await auctionStore.getCurrentActiveAuction();
      }
      emitter.emit(SocketHandleEmitterEvent.undoBidPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.changeArticle, async (data: IChangeArticleSocketHandlerData) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.changeArticle)) {
        return;
      }
      const newArticleNumber = data.ongoingArticle.number_optional || data.ongoingArticle.number
      console.log(`socket event received, route (${router.currentRoute.name}): change article (new article ${newArticleNumber})`)
      emitter.emit(SocketHandleEmitterEvent.changeArticlePreHandleEmitterEvent, data);
      if (rootStore.appFeatures.webSocketAuctionStateManagement) {
        const auctionNumber = auctionStore.currentAuction.code
        const mediaServer = auctionStore.currentAuction.mediaServer
        auctionStore.CHANGE_STATE({
          key: 'currentAuction',
          value: {
            ...auctionStore.currentAuction,
            highestBid: null,
            ongoingArticle: {
              ...auctionStore.currentAuction.ongoingArticle,
              ...data.ongoingArticle,
            }
          }
        })
      } else {
        await auctionStore.getCurrentActiveAuction();
      }
      emitter.emit(SocketHandleEmitterEvent.changeArticlePostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.sellItem, async (data: IUndoBidSocketHandlerData) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.sellItem)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): sell item`)
      emitter.emit(SocketHandleEmitterEvent.sellItemPreHandleEmitterEvent, data);
      emitter.emit(SocketHandleEmitterEvent.sellItemPostHandleEmitterEvent, data);
    })
  }

  /**
   * Load all socket handlers for the admin namespace
   * @param {any} socket - The socket instance
   * @return {void} - Nothing
   */
  private static loadAdminHandlers(socket: any): void {
    const rootStore = useRootStore();
    const auctionStore = useAuctionStore();
    const emitter = SocketHandlers.getEmitter();

    socket.on(SocketEvent.updateBid, async (data: IUpdateBidSocketHandlerData) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.updateBid)) {
        return;
      }
      console.log(`socket event received, route (${router.currentRoute.name}): new bid received (value ${data.highestBid.value})`)
      if (rootStore.appFeatures.webSocketBidding) {
        auctionStore.CHANGE_STATE({
          key: 'currentAuction',
          value: data
        });
      } else {
        // TODO why needed?
        await auctionStore.getCurrentActiveAuction();
      }
      auctionStore.CHANGE_STATE({
        key: 'loadingModal',
        value: false
      })
      emitter.emit(SocketHandleEmitterEvent.updateBidPostHandleEmitterEvent, data);
    })

    socket.on(SocketEvent.pushRealtimeUsersData, async (data) => {
      if (!SocketHandlers.checkHandlerRole(SocketEvent.pushRealtimeUsersData)) {
        return;
      }
      rootStore.SET_CLIENT_DATA(data);
      emitter.emit(SocketHandleEmitterEvent.clientListPostHandleEmitterEvent, data);
    })
  }
}
