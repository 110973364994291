import { defineStore } from 'pinia'
import { IApiCallEntry, IArticle, ICategory, IClientListEntry, IUser } from '@/api/types';
import configs from '../configs'
import _, { isArray } from 'lodash';
import {
  localFeatures,
  SocketConnectionStatus,
  SocketDisconnectReason,
  timeouts
} from '@/utils/constants';
import Vuetify from '@/plugins/vuetify';
import Vue from 'vue';
import useAuctionStore from '@/stores/auctionStore';
import SocketClient from '@/utils/socket';
import * as Sentry from '@sentry/vue';
import router from '@/router';
import { i18n } from '@/main';
import moment from 'moment/moment';
import {
  checkIfTokenIsValid,
  getAuthToken,
  getConnectedUser,
  removeAuthToken
} from '@/utils/token';
import dispatcher from '@/api/dispatch';
import GlobalEvents, { GlobalEventType } from '@/utils/globalEvents';
import { TabIdCoordinator } from 'browser-tab-id';

interface IRootStoreState {
  socket: Record<string, any>,
  appLoading: boolean,
  apiLoading: Array<IApiCallEntry>,
  userProfile: IUser,
  token: string,
  error: boolean,
  errorMessage: string,
  notificationList: Array<any> // eslint-disable-line
  showBottomNavigation: boolean,
  showEditProfileModal: boolean,
  showKnockdownsModal: boolean,
  showProductCatalogue: boolean,
  productCataloguePredefinedValue: boolean,
  showAuctionOverview: boolean,
  showAuctionModal: boolean,
  showAdminKnockdownsModal: boolean,
  appSettings: Record<string, any>,
  streamSettings: Record<string, any>,
  appLocalization: Record<string, any>,
  appFeatures: Record<string, any>
  clientList: Array<IClientListEntry>,
  guestList: Array<string>,
  realtimeClientData: any,
  appStoreLang: string,
  showArticlesList: boolean,
  cookiesStatus: boolean,
  auctionsFetchedArticles: Array<IArticle>
  isMobile: boolean,
  loginButtonDisabled: boolean,
  categories: Array<ICategory>
  alert: Array<Record<string, any>>
  sendMessageModal: boolean,
  disconnectedTime: number,
  adminDataChange: boolean,
  users: Array<IUser>,
  adminCategories: Array<ICategory>
  adminArticles: Array<IArticle>,
  isOffline: boolean
  isLogoutClicked: boolean,
  globalTheme: string,
  unexpectedError: string,
  adminDrawer: boolean,
  socketConnectionStatus: SocketConnectionStatus,
  socketDisconnectReason: SocketDisconnectReason,
  auctionJoined: number,
  preselectionData: IPreselectionData,
  showTfaDialog: boolean,
  browserTabId: string,
  lastUpdatedAdminCategories: number,
  lastUpdatedUsers: number,
  lastUpdatedAdminArticles: Record<string, any>,
  lastUpdatedArticles: Record<string, any>
}

interface IPreselectionData {
  selectedAuctionId: number // selected auction id through the auction selector for i.e. product catalogue
}

const useRootStore = defineStore('rootStore', {
  state (): IRootStoreState {
    return {
      socket: {
        customConnectionStatus: 'init'
      },
      appLoading: false, // flag to control if the global loading overlay should be displayed (display if false)
      apiLoading: [], // currently ongoing api calls
      userProfile: {} as IUser, // Current user profile
      token: '', // jwt (not used, can be removed)
      error: false,
      errorMessage: '',
      notificationList: [], // notifications (not used)
      showBottomNavigation: true, // Show bottom mobile notification bar
      showEditProfileModal: false, // Show user edit profile modal
      showKnockdownsModal: false, // Show user knockdowns profile modal
      showProductCatalogue: false, // Show user product catalogue
      productCataloguePredefinedValue: false,
      showAuctionOverview: true, // Show user auction dashboard
      showAuctionModal: false, // Show user knockdowns profile modal
      showAdminKnockdownsModal: false,
      appSettings: {}, // general app settings
      streamSettings: {}, // streaming settings
      appLocalization: {}, // localization settings
      appFeatures: {}, // app features
      clientList: [], // socket client list
      guestList: [], // socket guest list
      realtimeClientData: {}, // socket client data
      appStoreLang: '', // the current ui language
      showArticlesList: true, // flag to control if the article slider should be shown on the auction view for users and guests
      cookiesStatus: window.localStorage.getItem('vue-cookie-accept-decline-cookies') === 'accept', // if cookies are accepted by the user
      auctionsFetchedArticles: [], // fetched articles from an auction for product catalogue etc. (not for a running live auction)
      isMobile: window.innerWidth <= 968, // Flag which is set for mobile devices
      loginButtonDisabled: false, // Flag which disables the login button, used when restoring the config or db as the backend needs to restart
      categories: [], // fetched categories for the user frontend
      alert: [], // array of messages (success, error or messages sent by the admin)e either set by SET_ALERT or by SET_TEMP_ALERT
      sendMessageModal: false, // flag to control if the modal to send messages to users should be shown
      disconnectedTime: 0, // socket disconnected time
      adminDataChange: null, // flag to control if the data is changed via the websocket data push, triggers updates or the icon in the admin fe
      users: [], // fetched users for the admin frontend
      adminCategories: [], // fetched categories for the user frontend
      adminArticles: [], // fetched articles for the admin frontend (not used?)
      isOffline: false, // app offline status (socket), can take up to 15s to detect if no internet
      isLogoutClicked: false, // Flag which is set during logout (if the user clicked the button)
      globalTheme: configs.theme.globalTheme,
      unexpectedError: '', // unexpected error, triggers the fault page
      adminDrawer: true, // flag to control if the admin menu should be shown
      socketConnectionStatus: SocketConnectionStatus.init, // socket connection status
      socketDisconnectReason: SocketDisconnectReason.notDisconnected, // socket disconnect reason (see https://socket.io/docs/v4/client-api/#event-disconnect)
      auctionJoined: null, // auction id of the joined live auction, will be set to null on logout or kickout (this only updates if the user clicks on "yes" in the modal. It can be
      // that the user is already joined to the room
      preselectionData: {selectedAuctionId: null}, // selected auction id through the auction selector for i.e. product catalogue
      showTfaDialog: false, // flag to control if the tfa dialog should be shown
      browserTabId: null, // browser tab identification
      lastUpdatedAdminCategories: null,
      lastUpdatedUsers: null,
      lastUpdatedAdminArticles: {},
      lastUpdatedArticles: {}
    }
  },
  getters: {
    isAuthenticatedAsUser (state: any) {
      return !_.isEmpty(state.userProfile)
    }
  },
  actions: {
    UPDATE_GLOBAL_STATE(object: any) { // TODO use types
      // Array
      if (isArray(object) && object.length > 0) {
        object.forEach((item: any) => {
          this[item.key] = item.value
        })
      } else {
        this[object.key] = object.value
      }
    },
    APP_GLOBAL_STORE_LANG (lang:string) {
      this.appStoreLang = lang
    },
    SET_IS_MOBILE () {
      this.isMobile = window.innerWidth <= 968
    },
    SET_LOGIN_BUTTON_DISABLED (data: any) {
      this.loginButtonDisabled = data
    },
    SET_AUCTIONS_FETCHED_ARTICLES (payload: any) {
      Vue.set(this.auctionsFetchedArticles, `auction_${payload.auctionID}`, payload.articles)
      // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
      GlobalEvents.emitEvent(GlobalEventType.updateArticle)
    },
    UPDATE_AUCTIONS_FETCHED_ARTICLE (payload: any) {
      if (this.auctionsFetchedArticles[`auction_${payload.auctionID}`]) {
        const idx = this.auctionsFetchedArticles[`auction_${payload.auctionID}`].findIndex((el: any) => el.id === payload.article.id)
        this.auctionsFetchedArticles[`auction_${payload.auctionID}`].splice(idx, 1, {
          ...this.auctionsFetchedArticles[`auction_${payload.auctionID}`][idx],
          ...payload.article
        })
        // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
        GlobalEvents.emitEvent(GlobalEventType.updateArticle)
      }
    },
    REMOVE_AUCTIONS_FETCHED_ARTICLE (payload: any) {
      if (this.auctionsFetchedArticles[`auction_${payload.auctionID}`]) {
        const idx = this.auctionsFetchedArticles[`auction_${payload.auctionID}`].findIndex((el: any) => el.id === payload.article.id)
        this.auctionsFetchedArticles[`auction_${payload.auctionID}`].splice(idx, 1)
        // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
        GlobalEvents.emitEvent(GlobalEventType.updateArticle)
      }
    },
    ADD_AUCTIONS_FETCHED_ARTICLE (payload: any) {
      if (this.auctionsFetchedArticles[`auction_${payload.auctionID}`]) {
        this.auctionsFetchedArticles[`auction_${payload.auctionID}`].push(payload.article)
        // adding a watcher to auctionsFetchedArticles doesn't trigger, even if its a deep watcher
        GlobalEvents.emitEvent(GlobalEventType.updateArticle)
      }
    },
    SET_CATEGORIES (categories: any) {
      this.categories = categories
    },
    ADD_CATEGORY (payload: any) {
      this.categories.push(payload)
    },
    REMOVE_CATEGORY (payload: any) {
      const idx = this.categories.findIndex((el: any) => el.id === payload.id)
      this.categories.splice(idx, 1)
    },
    SET_ALERT (data: any) {
      this.alert = [data]
    },
    SET_TEMP_ALERT (data: any) {
      this.alert.push(data)
      setTimeout(() => {
        const index = this.alert.indexOf(data);
        if (index !== -1) {
          this.alert.splice(index, 1);
        }
      }, data.timeout || timeouts.closeToast)
    },

    CLEAR_ALERT () {
      this.alert = []
    },
    SET_SEND_MESSAGE_MODAL (data: any) {
      this.sendMessageModal = data
    },
    SET_DISCONNECTED_TIME (data: any) {
      this.disconnectedTime = data
    },
    SET_ADMIN_DATA_CHANGE (payload: any) {
      this.adminDataChange = payload
    },
    SET_USERS (payload: any) {
      this.users = payload
    },
    SET_ADMIN_CATEGORIES (payload: any) {
      this.adminCategories = payload
    },
    SET_ADMIN_ARTICLES (payload: any) {
      this.adminArticles = payload
    },
    SET_OFFLINE_STATUS (status: any) {
      this.isOffline = status
    },
    SET_LOGOUT_CLICKED (status: any) {
      this.isLogoutClicked = status
    },
    SET_SOCKET_CONNECTION_STATUS (status: SocketConnectionStatus) {
      this.socketConnectionStatus = status
    },
    SET_GLOBAL_THEME (theme: any) {
      Vuetify.framework.theme.dark = theme === 'dark'
      this.globalTheme = theme
    },
    SET_UNEXPECTED_ERROR (text: any) {
      this.unexpectedError = text
    },
    SET_ADMIN_DRAWER (drawer: any) {
      this.adminDrawer = drawer
    },
    SET_CLIENT_DATA (clientData: any): void {
      this.clientList = clientData.clients; // was clientData before guest functionality
      this.guestList = clientData.guests;
      this.realtimeClientData = clientData.realtimeUsersData;
    },
    SET_SOCKET_DISCONNECT_STATUS (status: SocketDisconnectReason): void {
      this.socketDisconnectReason = status
    },
    ADD_API_LOADING_ENTRY(apiCall: string, isSocket: boolean): any {
      const timestamp = new Date();
      this.apiLoading.push({
        apiCall,
        isSocket,
        timestamp
      });
      return timestamp;
    },
    LIST_CURRENT_API_CALLS(): void {
      for (const apiCallEntry of this.apiLoading) {
        console.log(`API call ${apiCallEntry.apiCall}, socket ${apiCallEntry.isSocket} started at ${apiCallEntry.timestamp}`);
      }
    },
    REMOVE_API_LOADING_ENTRY(apiCall: string, timestamp: any): void {
      const index = this.apiLoading.findIndex(e => {return e.apiCall === apiCall && e.timestamp === timestamp});
      if (index !== -1) {
        this.apiLoading.splice(index, 1);
      }
    },
    /**
     * Reset the app (disconnect from the socket if connected, reset the vuex to default state, redirect to the login page if not already there)
     */
    resetApp(): void {
      const auctionStore= useAuctionStore()
      console.log("resetApp action called")

      // Disconnect from the socket if connected
      if (this.socket) {
        if (this.socket.disconnect) { //needed for auctioneers screen and viewer screen)
          this.socket.disconnect()
        }
        if (this.socket.removeAllListeners) { //needed for auctioneers screen and viewer screen)
          this.socket.removeAllListeners()
        }
        SocketClient.removeInstance()
        console.log('socket disconnected after logout')
      } else {
        console.log('socket not disconnected as already closed from the backend')
      }

      // Update profile
      const localization = this.appLocalization
      this.UPDATE_GLOBAL_STATE({ key: 'userProfile', value: {} });
      auctionStore.$reset();
      this.$reset();
      this.UPDATE_GLOBAL_STATE({ key: 'appLocalization', value: localization })
      const cookies = localStorage.getItem('vue-cookie-accept-decline-cookies')
      localStorage.clear()
      if (cookies != null) {
        localStorage.setItem('vue-cookie-accept-decline-cookies', cookies)
      }
      Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
      if (localFeatures.useSentry) {
        Sentry.setUser(null);
      }
      // Back to login page
      // router.push({ name: 'login' })
      if (router.currentRoute.name !== 'login') router.push({ name: 'login' })
      this.getAppSettings()
    },
    /**
     * Log the user out (delete the jwt if it exists and is valid)
     */
    async logout (): Promise<void> {
      const auctionStore = useAuctionStore()
      console.log("logout action called")
      if (this.isLogoutClicked && !_.isEmpty(auctionStore.currentAuction)) {
        await this.leaveAuction(auctionStore.currentAuction.id, false);
      }
      const token = getAuthToken()
      if (token && checkIfTokenIsValid(token)) {
        dispatcher.logout().catch(e => {
          console.log('logout call failed, continuing')
        }).finally(() => {
          removeAuthToken()
          this.resetApp()
        })
      } else {
        removeAuthToken()
        this.resetApp()
      }

      /*
      const token = getAuthToken()
      if (token && checkIfTokenIsValid(token)) {
        apiRequests.logout().catch(e => {
          console.log('logout call failed, continuing')
        }).finally(() => {
          removeAuthToken()
        })
        dispatch('resetApp')
      } else {
        removeAuthToken()
        dispatch('resetApp')
      }
      */
    },
    /**
     * Run the kickout logic, this happens if the user gets kicked out (delete the jwt,disconnect from the socket if connected, reset the vuex to default state, redirect to the login page)
     */
    async kickOut () {
      console.log("kickout action called")
      // Remove token
      removeAuthToken()

      this.resetApp()
    },
    /**
     * Get user profile from current user (updates the store)
     * @return {<any>} - The user profile or null if empty
     */
    async getCurrentUserProfile (): Promise<IUser> { // TODO use the interface everywhere
      let result = await dispatcher.getUserProfile();
      this.UPDATE_GLOBAL_STATE({ key: 'userProfile', value: result});
      return result;
    },
    async getNotification () {
      try {
        let result = await dispatcher.getNotification(getConnectedUser());
        this.UPDATE_GLOBAL_STATE({ key: 'notificationList', value: result });
        return result;
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: i18n.t('There was an error loading the notifications. Please try again later') });
      }
    },
    async markReadNotification (id: number) {
      try {
        let result = await dispatcher.markReadNotification(getConnectedUser(), id);
        return result;
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: i18n.t('There was an error marking the notification as read. Please try again later') });
      }
    },
    /**
     * Update user profile (updates the store)
     * @return {boolean} - True if the profile has been updated, false if not due to errors
     */
    async updateUserProfile (data: any): Promise<void> {
      const result = await dispatcher.updateUserProfile(data);
      this.UPDATE_GLOBAL_STATE({ key: 'userProfile', value: result});
    },
    // Get user settings or null if there is an error (i.e. http 401)
    async getUserSettings () {
      try {
        let result = await dispatcher.getUserSettings();

        // get all settings
        this.UPDATE_GLOBAL_STATE({ key: 'appSettings', value: result.general });
        // get app features
        this.UPDATE_GLOBAL_STATE({ key: 'appFeatures', value: result.features });
        // get app localization settings
        this.UPDATE_GLOBAL_STATE({ key: 'appLocalization', value: result.localization });
        // get app stream settings
        this.UPDATE_GLOBAL_STATE({ key: 'streamSettings', value: result.builtinStreaming });
        return result;
      } catch (e) {
        return null
      }
    },
    // Get public settings
    async getAppSettings () {
      try {
        let result = await dispatcher.getAppSettings()

        // get all settings
        this.UPDATE_GLOBAL_STATE({ key: 'appSettings', value: result.general });
        // get app features
        this.UPDATE_GLOBAL_STATE({ key: 'appFeatures', value: result.features });
        // get app localization settings
        this.UPDATE_GLOBAL_STATE({ key: 'appLocalization', value: result.localization });
        return result;
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: i18n.t('There is a temporary error in the application. Please try again later') })
      }
    },
    // Update article data and save to local storage and cookie
    updateArticleData (payload: any) { //TODO inline this
      this.UPDATE_AUCTIONS_FETCHED_ARTICLE({
        auctionID: payload.auction_id,
        article: payload.article
      });
    },
    // Get articles from one auction
    async getArticles (payload: any) { //TODO move to auction store and unify with getAuctionArticles()
      const auctionID = payload.auctionID
      try {
        let timestampResponse;
        if (this.isAuthenticatedAsUser) {
          timestampResponse = await dispatcher.checkIfAuctionArticlesUpdated(auctionID);
        } else {
          timestampResponse = await dispatcher.checkIfAuctionArticlesUpdatedGuest(auctionID);
        }
        const timestampResponseTime = timestampResponse.updated_at;
        if (this.lastUpdatedArticles[auctionID.toString()] === undefined) {
          console.log('doing full article fetch')
          let articles;
          if (this.isAuthenticatedAsUser) {
            articles = await dispatcher.getAuctionArticles(auctionID)
          } else {
            articles = await dispatcher.getAuctionArticlesGuest(auctionID)
          }
          this.SET_AUCTIONS_FETCHED_ARTICLES({ auctionID, articles: articles });
          this.lastUpdatedArticles[auctionID.toString()] = timestampResponseTime;
        } else {
          const responseUpdateMoment = moment(timestampResponseTime)
          const articlesLastUpdatedMoment = moment(this.lastUpdatedArticles[auctionID.toString()])
          if (responseUpdateMoment.diff(articlesLastUpdatedMoment, 'seconds') > 0) {
            console.log('articles changed, doing dynamic article fetch')
            const dynamicArticles = await dispatcher.getArticlesDynamic(auctionID)
            const currentArticles = this.auctionsFetchedArticles[`auction_${payload.auctionID}`]

            let updatedArticles = 0;
            const articles = currentArticles.map(el => {
              const target = dynamicArticles.find((article: { id: any }) => el.id === article.id)
              if (target) {
                updatedArticles++
                return {
                  ...el,
                  ...target
                }
              }
              return el
            });
            console.log(`updated ${updatedArticles} articles`);
            this.SET_AUCTIONS_FETCHED_ARTICLES({ auctionID, articles: articles });
            this.lastUpdatedArticles[auctionID.toString()] = timestampResponseTime;
          } else {
            console.log('articles unchanged, skipping fetch')
          }
        }
      } catch (e) {
        this.SET_TEMP_ALERT({
          flavor: 'error',
          content: i18n.t('There was an error loading the data. Please try again later')
        })
      }
    },
    // Get articles from one auction for the admin frontend and update the store
    async getAdminArticles (payload: any) {
      const auctionID = payload.auctionID
      try {
        const timestampResponse = await dispatcher.checkIfAuctionArticlesUpdated(auctionID)
        const timestampResponseTime = timestampResponse.updated_at;
        let doFetch = false;
        if (this.lastUpdatedAdminArticles[auctionID.toString()] === undefined) {
          doFetch = true;
        } else {
          const responseUpdateMoment = moment(timestampResponseTime)
          const usersLastUpdatedMoment = moment(this.lastUpdatedUsers)
          if (responseUpdateMoment.diff(usersLastUpdatedMoment, 'seconds') > 0) {
            doFetch = true;
          }
        }

        if (doFetch) {
          console.log('doing full admin articles fetch')
          const response = await dispatcher.getAuctionArticles(auctionID)
          this.SET_AUCTIONS_FETCHED_ARTICLES({
            auctionID,
            articles: response
          });
          this.lastUpdatedAdminArticles[auctionID.toString()] = timestampResponseTime;
        } else {
          console.log('admin articles unchanged, skipping fetch')
        }
      } catch (e) {
        this.SET_TEMP_ALERT({
          flavor: 'error',
          content: i18n.t('There was an error loading the data. Please try again later')
        });
      }
    },
    // Get users for the admin frontend
    async fetchUsers () {
      try {
        const timestampResponse = await dispatcher.getUsersLastUpdatedTimestamp()
        const timestampResponseTime = timestampResponse.updated_at;
        let doFetch = false;
        if (this.lastUpdatedUsers === null) {
          doFetch = true;
        } else {
          const responseUpdateMoment = moment(timestampResponseTime)
          const usersLastUpdatedMoment = moment(this.lastUpdatedUsers)
          if (responseUpdateMoment.diff(usersLastUpdatedMoment, 'seconds') > 0) {
            doFetch = true;
          }
        }

        if (doFetch) {
          console.log('doing full users fetch')
          const result = await dispatcher.getUsers()
          this.SET_USERS(result);
          this.lastUpdatedUsers = timestampResponseTime;
        } else {
          console.log('users unchanged, skipping fetch')
        }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: i18n.t('There was an error loading the user data. Please try again later') });
      }
    },
    // Get categories for the admin frontend
    async fetchAdminCategories () {
      try {
        const timestampResponse = await dispatcher.getCategories(true);
        const timestampResponseTime = timestampResponse.updated_at;
        let doFetch = false;
        if (this.lastUpdatedAdminCategories === null) {
          doFetch = true;
        } else {
          const responseUpdateMoment = moment(timestampResponseTime)
          const adminCategoriesLastUpdatedMoment = moment(this.lastUpdatedAdminCategories)
          if (responseUpdateMoment.diff(adminCategoriesLastUpdatedMoment, 'seconds') > 0) {
            doFetch = true;
          }
        }

        if (doFetch) {
          console.log('doing full admin categories fetch')
          const result = await dispatcher.getCategories(false)
          this.SET_ADMIN_CATEGORIES(result);
          this.lastUpdatedAdminCategories = timestampResponseTime;
        } else {
          console.log('admin categories unchanged, skipping fetch')
        }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: i18n.t('There was an error loading the data. Please try again later') });
      }
    },
    /**
     * Get all categories and update the store
     */
    async fetchCategories () {
      let categoriesData;
      if (this.isAuthenticatedAsUser) {
        categoriesData = await dispatcher.getCategories(false);
      } else {
        categoriesData = await dispatcher.getCategoriesGuest(false);
      }
      this.SET_CATEGORIES(categoriesData)
      return categoriesData;
    },
    SET_SOCKET_DATA (data: any) {
      this.socket = data
    },
    /**
     * Join an auction (gains access to auction events on the socket), errors will be handled outside
     */
    async joinAuction (auctionId: number) {
      try {
        if (!this.auctionJoined) {
          await dispatcher.joinLiveAuction(auctionId, this.browserTabId) // exception will be handled in the join auction component
          this.UPDATE_GLOBAL_STATE({ key: 'auctionJoined', value: auctionId });
          await useAuctionStore().getCurrentActiveAuction();
        }
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: i18n.t('There was an error joining the auction. Please try again later') });
      }
    },
    /**
     * Leave the auction, makes sense only if the auction has been joined before
     */
    async leaveAuction (auctionId: number, force: boolean) {
      try {
        if (force || this.auctionJoined) await dispatcher.leaveLiveAuction(auctionId, this.browserTabId)
      } catch (e) {
        // happens if offline and the user clicks logout since there is no socket connection and api will also fail
      }
      if (this.auctionJoined) this.UPDATE_GLOBAL_STATE({key: 'auctionJoined', value: null});
    },
    /**
     * set the browser tab id
     */
    async setBrowserTabId () {
      const browserTabIdCoordinator = new TabIdCoordinator();
      this.UPDATE_GLOBAL_STATE({key: 'browserTabId', value: browserTabIdCoordinator.tabId});
    },
  }
})

export default useRootStore
