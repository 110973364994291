<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <v-card class="user-profile-container user-catalogue customizable">
      <div class="close-btn-wrapper" v-if="!modalPosition">
        <v-btn class="close-btn" @click="closeProfileModal()">
          <v-icon class="close-modal-icon">fa-times</v-icon>
        </v-btn>
        <v-btn v-if="isMobile && activeCategory==null" class="filter-icon mt-2" @click="openCategoryModal()">
          <v-icon>fa-filter</v-icon>
        </v-btn>
        <v-btn v-if="isMobile && activeCategory!=null" class="filter-icon mt-2" @click="setCategory(null)">
          <v-icon>fa-rotate-right</v-icon>
        </v-btn>
      </div>
      <v-card-text class="main-card" v-if="auctions">
        <v-container :class="{'no-max-width': gridView}">
          <div>
            <v-row align="center" class="form-container justify-center flex-column">
              <v-col sm="12" class="full-width">
                <h2 class="page-title text-center">{{ $t('Auctions') }}</h2>
                <v-col class="main-col-wrapper" v-if="!preselectedAuction || localySelected">
                  <v-col align="center" class="sub-col-wrapper">
                    <v-row>
                      <search-box
                        v-if="auctions && auctions.length > 0"
                        :currentAuction="currentAuction"
                        :auctions="auctions.filter(el => el.catalogue_enabled)"
                        hide-create-new
                        title=""
                        @onSelectAuction="selectAuction"
                        show-auction-type
                        hide-completed-auctions
                      />
                    </v-row>
                  </v-col>
                </v-col>
              </v-col>
              <div class="scroll-anchor" ref="anchor" />
              <v-progress-circular v-if="auctionLoading" class="loading-icon mt-6" indeterminate />
              <template v-else>
                <v-alert
                  v-if="!isAuthenticatedAsUser && visibleRegistrationAlert && features.guestLoginPrompt"
                  border="left"
                  dense
                  outlined
                  text
                  type="info"
                  dismissible
                >
                  {{ $t('Please note') }},
                  <span v-if="appFeatures.liveAuctionFunctionality">{{ $t('login_reminder_live') }}</span>
                  <span v-else>{{ $t('login_reminder_no_live') }}</span>
                  <br>
                  <a @click="openRegistrationModal">
                    <strong>{{ $t('Register') }}</strong>
                  </a>
                  / <a @click="openLogin">
                    <strong>{{ $t('Login now') }}</strong>
                  </a>
                </v-alert>
                <v-alert
                  v-if="isAuthenticatedAsUser && visibleAuctionSignupAlert && features.userAuctionSignupReminder && appFeatures.liveAuctionFunctionality && selectedAuctionData && selectedAuctionData.signup_enabled && selectedAuctionData.status !== 'completed' && isAuctionSignupPossible"
                  border="left"
                  dense
                  outlined
                  text
                  type="info"
                  dismissible
                >
                  {{ $t('You are not yet signed up for that auction. If you want to bid live') }}
                  <a @click="openAuctionSignupModal">
                    <strong>{{ $t('click here') }}</strong>
                  </a>
                </v-alert>
                <h3 class="auction-title mb-4" v-if="selectedAuctionData">{{ auctionName(selectedAuctionData) }}</h3>
                <auction-overview
                  v-if="selectedAuctionData"
                  :auction="selectedAuctionData"
                />
                <span class="auction-date mt-4" v-if="selectedAuctionData && selectedAuctionData.no_shipping">{{ $t('Please note') }}, {{ $t('that for this auction,shipping is not possible. All bought articles need to be picked up') }}</span>
                <v-col sm="12" v-if="!_.isEmpty(currentAuction) && !error && !_.isEmpty(articles)">
                  <v-text-field
                    v-model="search"
                    append-icon="fa-search"
                    :label="$t('Search lots by lot number or name')"
                    hide-details
                    class="mb-3"
                    @input="searchHandler"
                  />
                  <v-switch
                    v-model="showUnsold"
                    color="primary"
                    :label="$t('Show unsold articles only')"
                    @change="searchHandler"
                    hide-details
                    class="mb-2"
                    v-if="displayShowUnsoldSlider"
                  />
                  <div class="catalogue__header">
                    <h3 class="catalogue__sidebar-title" v-if="!categoriesError">{{ $t('Categories') }}</h3>
                    <div v-else/>
                    <div class="catalogue__header-buttons">
                      <v-icon class="icon-with-hover" :class="{ active: !gridView }" @click="gridView = !gridView">fa-list</v-icon>
                      <v-icon class="icon-with-hover" :class="{ active: gridView }" @click="gridView = !gridView">fa-th</v-icon>
                    </div>
                  </div>
                  <div class="catalogue" :class="{ 'list-view': !gridView }" :key="currentAuction.id">
                    <div class="catalogue__sidebar" v-if="categories && categories.length && !categoriesError">
                      <category-modal
                        ref="categoryModal"
                        :appStoreLang="appStoreLang"
                        @setCategory="setCategory"
                        :setCategory="setCategory"
                        :categories="categories"
                        :activeCategory="activeCategory"
                        :isMobile="isMobile"
                        v-if="isMobile"
                      >
                      </category-modal>
                      <categories-filter
                        :appStoreLang="appStoreLang"
                        @setCategory="setCategory"
                        :categories="categories"
                        :activeCategory="activeCategory"
                        :isMobile="isMobile"
                        v-else
                      >
                      </categories-filter>
                    </div>
                    <div class="catalogue__list" v-if="renderComponent">
                      <template v-if="articlesView.length">
                        <div
                          class="catalogue__item"
                          :class="{ highlight: article.is_highlight }"
                          v-for="(article, i) in articlesView"
                          :key="article.id"
                          ref="article"
                          @click="gridView ? openArticleDetailedModal(article) : false"
                          :style="gridView && itemsPerRow >= 3 ? `width: calc(${100 / itemsPerRow}% - 30px)` : ''"
                        >
                          <div class="catalogue__item-header">
                            <p class="catalogue__item-highlight" v-if="article.is_highlight">{{ $t('Highlight') }}</p>
                            <v-icon class="icon-search-circle icon-with-hover mr-2" small v-if="gridView || isMobile" @click.stop="openArticleDetailedModal(article)">fa-search</v-icon>
                            <v-tooltip top allow-overflow>
                              <template #activator="{ on, attrs }">
                                <svg
                                  v-bind="attrs"
                                  v-on="on"
                                  height="30px"
                                  width="30px"
                                  :fill="globalTheme === 'dark' ? '#ffffff' : '#000000'"
                                  xmlns="http://www.w3.org/2000/svg"
                                  data-name="Layer 101"
                                  viewBox="0 0 100 100" x="0px" y="0px"
                                  class="catalogue__item-shipping icon-with-hover mr-2"
                                  v-if="article.no_shipping || selectedAuctionData.no_shipping"
                                >
                                  <path d="M50,90A40,40,0,1,0,10,50,40,40,0,0,0,50,90Zm0-73.23a33.1,33.1,0,0,1,21,7.46l-4.39,4.39a.63.63,0,0,1,.16.26L69,35.76,75.77,29A33.23,33.23,0,0,1,29,75.77l4.13-4.13h-2.4a.76.76,0,0,1-.75-.75V65.21L24.23,71A33.23,33.23,0,0,1,50,16.77Z" /><path d="M68.5,70.14V39.56H54v8.87a.76.76,0,0,1-.75.75H46.79a.76.76,0,0,1-.75-.75V39.56H31.5V70.14Z" /><polygon points="31.79 38.05 46.08 38.05 46.53 29.86 34.5 29.86 31.79 38.05" /><polygon points="53.92 38.05 68.21 38.05 65.5 29.86 53.47 29.86 53.92 38.05" /><polygon points="52.42 38.05 51.96 29.86 48.04 29.86 47.58 38.05 52.42 38.05" /><rect x="47.54" y="39.56" width="4.92" height="8.13" />
                                </svg>
                              </template>
                              <span>{{ $t('No shipping possible') }}</span>
                            </v-tooltip>
                            <v-tooltip top allow-overflow v-if="enableArticleInqirires">
                              <template #activator="{ on, attrs }">
                                <div
                                  v-bind="attrs"
                                  v-on="on"
                                  class="catalogue__item-inquiry icon-with-hover mr-2"
                                  @click.stop="openArticleInquiryModal(article)"
                                  v-if="isAuthenticatedAsUser"
                                >
                                  <v-icon>fa-question-circle</v-icon>
                                </div>
                              </template>
                              <span>{{ $t('Lot inquiry') }}</span>
                            </v-tooltip>
                            <v-tooltip top allow-overflow :key="tableKey">
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  class="catalogue__item-wish icon-with-hover"
                                  :class="{ active: article.wishlist }"
                                  @click.stop="addToWishList(article)"
                                  :disabled="wishlistError"
                                  v-if="isAuthenticatedAsUser"
                                >
                                  <v-icon :style="{ fontWeight: article.wishlist ? 900 : 400 }">fa-heart</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('My Wishlist') }}</span>
                            </v-tooltip>
                          </div>
                          <div class="catalogue__item-body">
                            <div class="catalogue__show-image" v-show="article.offensive && !showImages[`article${article.id}`]">
                              <div class="info-text" @click.stop="showImageToggle(article.id)" v-if="isAuthenticatedAsUser">
                                <v-icon>fa-info-circle</v-icon>
                                <p class="font-10">
                                  {{ $t('offensive_article_button_show_image') }}<br>
                                  <a :href="appSettings.url_rules" target="_blank">{{ $t('offensive_article_button_show_image2') }}</a>
                                </p>
                              </div>
                              <v-btn class="btn old-styling-btn wrap-text" @click.stop="openLogin" v-else>{{ $t('Login to see images') }}</v-btn>
                            </div>
                            <div
                              class="catalogue__image"
                              ref="carousel"
                              v-show="showImages[`article${article.id}`] || !article.offensive"
                              v-if="article.images && article.images.length && article.images.length <= 1"
                              @click.stop="e => selectImage(e, article.images, 0)"
                            >
                              <v-img contain :src="article.images[0].image" :style="{'max-height': isMobile ? '114px' : '156px'}"/>
                            </div>
                            <div class="catalogue__slider" v-else-if="article.images && article.images.length" v-show="showImages[`article${article.id}`] || !article.offensive">
                              <div class="custom-arrow slick-arrow slick-next" @click.stop="$refs.carousel[i].next()">
                                <v-icon class="arrow-left">fa-angle-right</v-icon>
                              </div>
                              <div class="custom-arrow slick-arrow slick-prev" @click.stop="$refs.carousel[i].prev()">
                                <v-icon class="arrow-right">fa-angle-left</v-icon>
                              </div>
                              <VueSlickCarousel ref="carousel" lazyLoad="ondemand" :arrows="false" :key="article.images.length" :dots="false">
                                <div
                                  class="image-item"
                                  v-for="(itm, idx) in article.images"
                                  :key="itm.articleNumber"
                                  @click.stop="e => selectImage(e, article.images, idx)"
                                  @keydown.left.stop=""
                                  @keydown.right.stop=""
                                >
                                  <v-img contain class="article-image" :src="itm.image" :style="{'max-height': isMobile ? '114px' : '156px'}"></v-img>
                                </div>
                              </VueSlickCarousel>
                            </div>
                            <div class="catalogue__image cursor-pointer" ref="carousel" v-else />
                            <div class="catalogue__item-content" :style="{ width: article.images && article.images.length ? '' : '100%' }">
                              <h3 class="catalogue__name">{{ articleTitle(article) }}</h3>
                              <v-row justify="space-between" align="center" class="ma-0">
                                <v-col cols="12" class="pt-0 pb-0 pl-0">
                                  <div class="catalogue__meta">
                                    <div class="catalogue__meta-item">
                                      <p class="catalogue__meta-name">{{ $t('Article') }}</p>
                                      <h5 class="catalogue__meta-value">#{{ article.number_optional || article.number }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="article.limit && !selectedAuctionIsSale">
                                      <p class="catalogue__meta-name" v-if="selectedAuctionData.type === 'timed'">{{ $t('Start price') }}</p>
                                      <p class="catalogue__meta-name" v-else>{{ $t('Limit') }}</p>
                                      <h5 class="catalogue__meta-value">{{ currency }} {{ euNumbers(article.limit) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-else-if="article.buy_price && selectedAuctionIsSale">
                                      <p class="catalogue__meta-name">{{ $t('Buy price') }}</p>
                                      <h5 class="catalogue__meta-value">{{ currency }} {{ euNumbers(article.buy_price) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type !== 'timed' && !selectedAuctionIsSale && article.estimated_price && (selectedAuctionData.catalogue_enabled === 'enabled' || selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations')">
                                      <p class="catalogue__meta-name">{{ $t('Estimate') }}</p>
                                      <h5 class="catalogue__meta-value">{{ currency }} {{ article.estimated_price }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData">
                                      <p class="catalogue__meta-name">{{ $t('Current price') }}</p>
                                      <h5 class="catalogue__meta-value" v-if="article.timedData.current_hammer_price">{{ currency }} {{ euNumbers(article.timedData.current_hammer_price) }}</h5>
                                      <h5 class="catalogue__meta-value" v-else>-</h5>
                                    </div>
                                    <!--                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData && article.timedData.active_from">-->
                                    <!--                                      <p class="catalogue__meta-name" v-translate>Starts at</p>-->
                                    <!--                                      <h5 class="catalogue__meta-value">{{ getFormattedDate(article.timedData.active_from, appStoreLang, true) }}</h5>-->
                                    <!--                                    </div>-->
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData && article.timedData.active_until">
                                      <p class="catalogue__meta-name">{{ $t('Ends at') }}</p>
                                      <h5 class="catalogue__meta-value">{{ getFormattedDate(article.timedData.active_until, appStoreLang, true, false) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'timed' && article.timedData && article.timedData.active_until && getRemainingTime(article.timedData.active_until, redrawRemainingTime)">
                                      <p class="catalogue__meta-name">{{ $t('Remaining time') }}</p>
                                      <h5 class="catalogue__meta-value">{{ getRemainingTime(article.timedData.active_until, redrawRemainingTime) }}</h5>
                                    </div>
                                    <div class="catalogue__meta-item" v-if="selectedAuctionData.type === 'live' && selectedAuctionData.status !== 'preparing' && (selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations' || selectedAuctionData.catalogue_enabled === 'enabled_with_results')">
                                      <p class="catalogue__meta-name">{{ $t('Knockdown') }}</p>
                                      <h5 class="catalogue__meta-value" v-if="article.sold_price">{{ currency }} {{ euNumbers(article.sold_price) }}</h5>
                                      <h5 class="catalogue__meta-value" v-else>-</h5>
                                    </div>
                                    <v-row class="ma-0 justify-md-end justify-start">
                                      <v-btn
                                        class="btn catalogue__item-shopping mb-3 mb-sm-0 old-styling-btn"
                                        @click.stop="openPostAuctionSaleModal(article)"
                                        v-if="isAuthenticatedAsUser && displayPostAuctionBtn && !article.sold_price && !gridView && (article.status === '3' || article.status === '4')"
                                      >
                                        <v-icon class="mr-2">fa-shopping-cart</v-icon><span>{{ $t('Post auction sale') }}</span>
                                      </v-btn>
                                      <v-col class="pt-0 pl-0 flex-grow-0 text-md-right" v-if="isAuthenticatedAsUser && (selectedAuctionIsSale || selectedAuctionData.type === 'timed') && (!gridView || isMobile)">
                                        <v-btn
                                          @click.stop="buyArticle(article)"
                                          class="btn success mt-2 mr-2 old-styling-btn"
                                          v-if="(article.status === '2' || (selectedAuctionData.status === 'started' && selectedAuctionIsSale)) && article.buy_price && article.instant_buy_possible"
                                        >
                                          <v-icon small class="mr-2">fa-shopping-cart</v-icon>
                                          <span>{{ $t('Buy now') }}</span>
                                        </v-btn>
                                        <p
                                          class="mt-2 mb-0 text-no-wrap"
                                          v-if="article.status === '2' && !timedError && article.timedData"
                                          readonly
                                        >
                                          <span class="green--text" v-if="article.timedData.bidder_status === 'highest-bidder'">{{ $t('You are the highest bidder') }}</span>
                                          <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{ $t('You are outbid, bid again') }}</span>
                                        </p>
                                        <v-btn
                                          class="btn small mt-2 old-styling-btn"
                                          @click.stop="openTimedBidModal(article)"
                                          v-if="article.status === '2' && selectedAuctionData.status === 'started' && !timedError && article.timedData"
                                        >
                                          <span v-if="article.timedData.bidder_status === 'no-bid'">{{ $t('Place bid') }}</span>
                                          <span v-else>{{ $t('Place/Check bid') }}</span>
                                        </v-btn>
                                        <p
                                          class="mt-2 mb-0 text-no-wrap"
                                          v-else-if="article.status === '0' && !timedError"
                                          readonly
                                        >
                                          <span class="green--text" v-if="article.timedData.bidder_status === 'bought'">{{ $t('You have bought the article') }}</span>
                                          <span class="green--text" v-if="article.timedData.bidder_status === 'highest-bidder'">{{ $t('You have auctioned the article') }}</span>
                                          <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{ $t('Article sold') }}</span>
                                        </p>
                                      </v-col>
                                      <div :class="{ 'full-width': gridView }" v-if="isAuthenticatedAsUser && !gridView && selectedAuctionData.commission_bids_enabled && selectedAuctionData.type === 'live' && selectedAuctionData.status !== 'completed'">
                                        <v-btn
                                          class="btn catalogue__item-shopping mb-3 old-styling-btn"
                                          :class="{ small: gridView }"
                                          @click.stop="openBidModal(article)"
                                          v-if="(!article.commission_bids || !article.commission_bids.length) && article.status === '1'"
                                          :disabled="commissionError"
                                          key="action-btn"
                                        >
                                          <span>{{ $t('+ Submit absentee bid') }}</span>
                                        </v-btn>
                                        <v-tooltip left v-if="article.commission_bids && article.commission_bids.length">
                                          <template v-slot:activator="{ on, attrs }">
                                            <div v-on="on">
                                              <v-btn class="btn mb-3 bid-status" :class="article.commission_bids[0].status" disabled key="status-btn">
                                                <v-icon v-if="article.commission_bids[0].status === 'pending'" style="font-weight: 400;" class="mr-1">fa-hourglass</v-icon>
                                                <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                                <v-icon v-if="article.commission_bids[0].status === 'accepted'" class="green-color-success mr-1">fa-check</v-icon>
                                                <span v-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                                <v-icon v-if="article.commission_bids[0].status === 'declined'" class="red-color mr-1">fa-times</v-icon>
                                                <span v-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                              </v-btn>
                                            </div>
                                          </template>
                                          <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid pending') }}</span>
                                          <span v-else-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid accepted') }}</span>
                                          <span v-else-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid declined') }}</span>
                                        </v-tooltip>
                                      </div>
                                    </v-row>
                                  </div>
                                  <v-row class="pb-3 px-3 justify-start justify-md-end" v-if="article.commission_bids && article.commission_bids.length && !gridView && selectedAuctionData.status !== 'completed' && article.status === '1'">
                                    <a class="catalogue__item-change" @click.stop="openBidModal(article, true)">{{ $t('Change bid') }}</a>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <p v-if="useHtml" class="catalogue__description" v-html="articleDescription(article)"></p>
                              <p v-else class="catalogue__description">{{ articleDescription(article) }}</p>
                              <template v-if="isAuthenticatedAsUser && (selectedAuctionIsSale || selectedAuctionData.type === 'timed') && gridView && !isMobile">
                                <v-btn
                                  @click.stop="buyArticle(article)"
                                  class="btn success mt-2 mr-2 small old-styling-btn"
                                  v-if="(article.status === '2' || (selectedAuctionData.status === 'started' && selectedAuctionIsSale)) && article.buy_price && article.instant_buy_possible"
                                >
                                  <v-icon small class="mr-2">fa-shopping-cart</v-icon>
                                  <span>{{ $t('Buy now') }}</span>
                                </v-btn>
                                <p
                                  class="mt-2 mb-0"
                                  v-if="article.status === '2' && !timedError && article.timedData"
                                  readonly
                                >
                                  <span class="green--text" v-if="article.timedData.bidder_status === 'highest-bidder'">{{ $t('You are the highest bidder') }}</span>
                                  <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{ $t('You are outbid, bid again') }}</span>
                                </p>
                                <v-btn
                                  class="btn catalogue__item-shopping small mt-2 old-styling-btn"
                                  @click.stop="openTimedBidModal(article)"
                                  v-if="article.status === '2' && selectedAuctionData.status === 'started' && !timedError && article.timedData"
                                >
                                  <span v-if="article.timedData.bidder_status === 'no-bid'">{{ $t('Place bid') }}</span>
                                  <span v-else>{{ $t('Place/Check bid') }}</span>
                                </v-btn>
                                <p
                                  class="mt-2"
                                  v-else-if="article.status === '0' && !timedError"
                                  readonly
                                >
                                  <span class="green--text" v-if="article.timedData.bidder_status === 'bought'">{{ $t('You have bought the article') }}</span>
                                  <span class="green--text" v-if="article.timedData.bidder_status === 'highest-bidder'">{{ $t('You have auctioned the article') }}</span>
                                  <span class="red--text" v-if="article.timedData.bidder_status === 'outbid'">{{ $t('Article sold') }}</span>
                                </p>
                              </template>
                              <template v-if="isAuthenticatedAsUser && gridView && selectedAuctionData.commission_bids_enabled && selectedAuctionData.type === 'live' && selectedAuctionData.status !== 'completed'">
                                <v-btn
                                  class="btn catalogue__item-shopping small mt-2 old-styling-btn"
                                  @click.stop="openBidModal(article)"
                                  v-if="!article.commission_bids.length && article.status === '1'"
                                  :disabled="commissionError"
                                >
                                  <span>{{ $t('+ Submit absentee bid') }}</span>
                                </v-btn>
                                <v-tooltip bottom v-if="article.commission_bids && article.commission_bids.length">
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-on="on">
                                      <v-btn class="btn catalogue__item-status small mt-2 old-styling-btn" :class="article.commission_bids[0].status" disabled key="status-btn">
                                        <v-icon v-if="article.commission_bids[0].status === 'pending'" style="font-weight: 400;" class="mr-1">fa-hourglass</v-icon>
                                        <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                        <v-icon v-if="article.commission_bids[0].status === 'accepted'" class="green-color-success mr-1">fa-check</v-icon>
                                        <span v-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                        <v-icon v-if="article.commission_bids[0].status === 'declined'" class="red-color mr-1">fa-times</v-icon>
                                        <span v-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid') }} {{ euNumbers(article.commission_bids[0].commission_bid) }} {{ currency }}</span>
                                      </v-btn>
                                    </div>
                                  </template>
                                  <span v-if="article.commission_bids[0].status === 'pending'">{{ $t('Absentee bid pending') }}</span>
                                  <span v-else-if="article.commission_bids[0].status === 'accepted'">{{ $t('Absentee bid accepted') }}</span>
                                  <span v-else-if="article.commission_bids[0].status === 'declined'">{{ $t('Absentee bid declined') }}</span>
                                </v-tooltip>
                              </template>
                              <v-row class="pb-3 px-3 justify-start mt-md-0 mt-2" v-if="article.commission_bids && article.commission_bids.length && gridView && selectedAuctionData.status !== 'completed' && article.status === '1'">
                                <a class="catalogue__item-change" @click.stop="openBidModal(article, true)">{{ $t('Change bid') }}</a>
                              </v-row>
                              <v-btn
                                class="btn catalogue__item-shopping small old-styling-btn"
                                @click.stop="openPostAuctionSaleModal(article)"
                                v-if="isAuthenticatedAsUser && displayPostAuctionBtn && !article.sold_price && gridView && (article.status === '3' || article.status === '4')"
                              >
                                <v-icon class="mr-2">fa-shopping-cart</v-icon><span>{{ $t('Post auction sale') }}</span>
                              </v-btn>
                            </div>
                          </div>
                        </div>
                      </template>
                      <div class="info-text" v-else>
                        <v-icon>fa-info-circle</v-icon>
                        <p>{{ $t('No articles are found') }}</p>
                      </div>
                    </div>
                  </div>
                </v-col>
                <div class="info-text mt-6" v-else-if="!_.isEmpty(currentAuction) && _.isEmpty(articles)">
                  <v-icon>fa-info-circle</v-icon>
                  <p>{{ $t('No articles are found') }}</p>
                </div>
                <v-col class="text-center" v-if="!selectedAuctionData && getAuctionError">
                  <div class="status__item status__item--error">
                    <div class="status__icon" />
                    <p class="status__text">{{ $t(`This auction doesn't exist`) }}</p>
                  </div>
                </v-col>
              </template>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
    <div class="pagination-container customizable" v-if="auctions && totalItems">
      <v-row class="ma-0" justify="center" align="center">
        <v-col class="pa-0">
          <v-row class="ma-0 d-flex" align="center" :justify="isMobile ? 'space-around' : 'start'">
            <v-menu offset-y close-on-content-click>
              <template #activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  class="dropdown-btn customizable"
                >
                  <span class="highlight">{{ countPerPageDefault }}<v-icon class="ml-1">fa-angle-down</v-icon></span>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in itemsPerPage"
                  :key="index"
                  @click="changePerPage(item)"
                >
                  <v-list-item-title class="text-right">{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="ml-2 mr-2 pagination-pages" v-if="!isMobile">
              {{ page * countPerPageDefault + 1 }}-{{ totalPages > 1 ? totalPages === page + 1 ? totalItems : (page + 1) * countPerPageDefault : totalItems }} {{ $t('of') }} {{ totalItems }} {{ $t('items') }}
            </div>
            <div class="wish-list-separator" />
            <v-btn
              @click="openWishList"
              v-if="isAuthenticatedAsUser && !_.isEmpty(currentAuction)"
              class="btn old-styling-btn ml-0 wrap-text"
              :disabled="wishlistError"
              style="max-width: 45%"
            >
              <v-icon class="mr-2">fa-heart</v-icon>
              {{ $t('View Wishlist') }}
            </v-btn>
            <v-btn
              @click="openBidsView"
              v-if="isAuthenticatedAsUser && selectedAuctionData.type === 'live' && moment(selectedAuctionData.active_until).diff(moment(), 'seconds') > 0 && selectedAuctionData.catalogue_enabled !== 'disabled' && selectedAuctionData.commission_bids_enabled && selectedAuctionData.status !== 'completed'"
              class="btn old-styling-btn ml-2 wrap-text"
              :disabled="commissionError"
              style="max-width: 45%"
            >
              <v-icon class="mr-2" style="font-weight: 900">fa-gavel</v-icon>
              {{ $t('View absentee bids') }}
            </v-btn>
            <v-btn
              @click="openBidsView"
              v-else-if="isAuthenticatedAsUser && selectedAuctionData.type === 'timed' && moment(selectedAuctionData.active_until).diff(moment(), 'seconds') > 0 && selectedAuctionData.catalogue_enabled !== 'disabled' && selectedAuctionData.status !== 'completed' && selectedAuctionData.status !== 'stopped'"
              class="btn old-styling-btn ml-2 wrap-text"
              :disabled="commissionError"
              style="max-width: 45%"
            >
              <v-icon class="mr-2" style="font-weight: 900">fa-gavel</v-icon>
              {{ $t('View bids') }}
            </v-btn>
          </v-row>
        </v-col>
        <custom-pagination
          v-if="totalPages > 1"
          :page="page + 1"
          @changePage="changePage"
          :length="totalPages"
          :totalVisible="10"
        />
      </v-row>
    </div>
    <CoolLightBox
      :items="selectedImages"
      :index="lightBoxIndex"
      :useZoomBar="true"
      :disableZoom=disableZoomOnMobile
      :closeOnClickOutsideMobile="true"
      @close="lightBoxIndex = null"
    />
    <user-article-inquiry-modal
      ref="inquiryModal"
      :article="selectedArticle"
      @clearSelectedArticle="selectedArticle = {}"
    />
    <change-bid-modal
      :commissionBidDialog="bidDialog"
      :editBid="editBid"
      :loading="loading"
      :selectedArticle="selectedArticle"
      :activePage="page"
      :activeCategory="activeCategory"
      :selectedAuctionData="selectedAuctionData"
      update-data
      @getAuctionDetail="getAuctionDetail"
      @setLoading="e => loading = e"
      @closeBidModal="closeBidModal"
    />
    <timed-bid-modal
      :auction="selectedAuctionData"
      :article="selectedArticle"
      ref="timedBidModal"
      :key="modalKey"
    />
    <confirmation-modal
      :title="`${$t('Do you really want to buy the article for')} ${appLocalization.currency} ${euNumbers(selectedArticle.buy_price)}`"
      @submit="submitBuy"
      @cancel="cancelBuy"
      ref="confirmationModal"
    />
    <instant-buy-modal
      ref="instantBuyModal"
      :loading="instantBuyLoading"
      @submitBuyArticle="submitBuyArticle"
      @clearSelectedArticle="selectedArticle = {}"
    />
    <article-details-modal
      ref="articleDetailsModal"
      :article="selectedDetailedArticle"
      :commission-error="commissionError"
      :timed-error="timedError"
      :wish-list-error="wishlistError"
      :selected-auction-data="selectedAuctionData"
      :prev-article="prevArticle"
      :next-article="nextArticle"
      @openArticleDetailedModal="openArticleDetailedModal"
      @closeArticleDetailedModal="closeArticleDetailsModal"
    />
    <registration ref="registrationModal" />
    <user-auction-signup-modal
      v-if="currentAuction"
      ref="auctionSignupModal"
      :user-id="userProfile.id"
      :auction-id="currentAuction.id"
      :require-bid-limit="auctionRequireBidLimit"
      :selected-auction-data="selectedAuctionData"
    />
    <post-auction-sale-modal
      :post-auction-sale-modal="postAuctionSaleModal"
      :article="selectedArticle"
      @closePostAuctionSaleModal="closePostAuctionSaleModal"
    />
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapState } from 'pinia'
import SearchBox from '@/views/settings/articles/sections/search'
import ChangeBidModal from '@/components/modals/user-commission-bid-modal'
import TimedBidModal from '@/components/modals/user-timed-bid-modal'
import ConfirmationModal from '@/components/modals/confirmation-modal'
import InstantBuyModal from '@/components/modals/user-request-instant-buy'
import UserArticleInquiryModal from '@/components/modals/user-article-inquiry-modal'
import Registration from '@/components/modals/guest-registration-modal'
import articleDetailsModal from '@/components/modals/article-details-modal'
import articleDetails from '@/mixins/article-details'
import counter from '@/mixins/counter'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {
  timeouts,
  localFeatures,
  BidderNumberType,
  AuctionFlavor, AuctionStatusType
} from '@/utils/constants'
import CategoriesFilter from '@/components/blocks/categories-filter'
import CategoryModal from '@/components/modals/category-modal'
import UserAuctionSignupModal from '@/components/modals/user-auction-signup-modal';
import PostAuctionSaleModal from '@/components/modals/user-post-auction-sale-modal.vue';
import CustomPagination from '@/components/blocks/custom-pagination.vue'
import dispatcher from '@/api/dispatch';
import { getBidderNumbersFromUser } from '@/services/user';
import moment from 'moment';
import useRootStore from '@/stores/rootStore';
import GlobalEvents, { GlobalEventType } from '@/utils/globalEvents';
import { createArticleSearchIndex, searchArticles } from '@/services/article';
import { euNumbers, getTranslatedText } from '@/services/i18n';
import { getFormattedDate } from '@/services/i18n';
import AuctionOverview from '@/components/blocks/auction-overview.vue';
import { getImageFullUrl, getImageOrFallback } from '@/services/auction';

export default {
  name: 'ProductCatalogueModal',
  components: {
    AuctionOverview,
    PostAuctionSaleModal,
    SearchBox,
    VueSlickCarousel,
    CoolLightBox,
    ChangeBidModal,
    TimedBidModal,
    ConfirmationModal,
    InstantBuyModal,
    UserArticleInquiryModal,
    articleDetailsModal,
    Registration,
    UserAuctionSignupModal,
    CategoriesFilter,
    CategoryModal,
    CustomPagination,
  },
  data: () => ({
    renderComponent: true,
    showProfileModal: false,
    instantBuyLoading: false,
    modalPosition: false,
    profileLocalization: {},
    currentAuction: {},
    search: '',
    countPerPageDefault: localFeatures.elementsPerPageDefault,
    itemsPerPage: localFeatures.elementsPerPage,
    page: 0,
    start: 0,
    end: 0,
    key: 0,
    error: '',
    articlesSearchFiltered: [],
    loading: false,
    searchResult: [],
    gridView: localFeatures.gridViewActiveByDefault,
    activeCategory: null,
    articlesView: [],
    wishListData: [],
    modalKey: 0,
    lightBoxIndex: null,
    selectedImages: [],
    auctions: null,
    getAuctionError: false,
    postAuctionSaleModal: false,
    bidDialog: false,
    question: '',
    disableButton: true,
    auctionLoading: false,
    selectedArticle: {},
    showUnsold: false,
    commissionError: false,
    categoriesError: false,
    wishlistError: false,
    editBid: false,
    tableKey: 0,
    timedError: false,
    initArticles: [],
    localySelected: true,
    itemsPerRow: localFeatures.gridViewItemsPerRow,
    showImages: {},
    visibleRegistrationAlert: true,
    features: localFeatures,
    visibleAuctionSignupAlert: true,
    auctionRequireBidLimit: false,
    totalItems: 0,
    articlesViewUnsliced: [],
    searchKeys: ['name', 'description'], // number and number_optional will always be searched
    articleSearchIndex: null,
    useHtml: localFeatures.useHtml,
    enableArticleInqirires: localFeatures.enableArticleInqirires
  }),
  mixins: [
    articleDetails,
    counter
  ],
  computed: {
    ...mapState(useRootStore, ['globalTheme', 'userProfile', 'isMobile', 'appLocalization', 'productCataloguePredefinedValue', 'appFeatures', 'appSettings', 'appStoreLang', 'auctionsFetchedArticles', 'isMobile', 'categories', 'isAuthenticatedAsUser', 'preselectionData', 'auctionUpdateRequired']),
    totalPages() {
      return Math.ceil(this.totalItems / this.countPerPageDefault)
    },
    emptyFields() {
      return false
    },
    disabledSave() {
      return true
    },
    headers() {
      return [
        {
          text: this.$t('Article #'),
          value: 'number',
        },
        {
          text: this.$t('Article name'),
          value: 'name',
        },
        {
          text: this.$t('Description'),
          value: 'description',
        }
      ]
    },
    disabled () {
      return !this.currentAuction
    },
    currency () {
      return this.appLocalization.currency
    },
    selectedAuctionData() {
      return this.auctions && this.currentAuction && this.auctions.find(el => el.id === this.currentAuction.id)
    },
    displayPostAuctionBtn() {
      return this.selectedAuctionData && (this.selectedAuctionData.catalogue_enabled === 'enabled_with_results' || this.selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations') && this.selectedAuctionData.post_auction_sale_enabled && this.selectedAuctionData.status !== AuctionStatusType.preparing && this.selectedAuctionData.type === AuctionFlavor.live
    },
    displayShowUnsoldSlider() {
      return this.selectedAuctionData && (this.selectedAuctionData.catalogue_enabled === 'enabled_with_results' || this.selectedAuctionData.catalogue_enabled === 'enabled_with_results_and_estimations') && this.selectedAuctionData.status === 'completed'
    },
    selectedAuctionIsSale() {
      return this.selectedAuctionData && this.selectedAuctionData.type === 'sale'
    },
    preselectedAuction() {
      return this.$route.params.id
    },
    isAuctionSignupPossible() {
      if (this.isAuthenticatedAsUser) {
        const target = this.bidderNumbers.filter(el => el.auction_id === this.currentAuction.id && el.type === BidderNumberType.live)
        return (_.isEmpty(target) && (this.currentAuction.type === AuctionFlavor.live))
      } else {
        return false
      }
    },
    disableZoomOnMobile() {
      return !!this.isMobile
    },
  },
  async created() {
    this.gridView = this.isMobile ? false : this.gridView;
    await this.getAuctions()
    if (this.preselectedAuction) {
      this.auctionLoading = true
      this.localySelected = false
      this.currentAuction = this.auctions.find(el => el.code.toString() === this.preselectedAuction.toString())
      if (this.currentAuction) {
        await this.getAuctionDetail(this.currentAuction.id)
        if (this.$route.query.categoryId) this.setCategory(+this.$route.query.categoryId)
        if (this.$route.query.page) {
          if (this.$route.query.page <= this.totalPages) {
            this.changePage(this.$route.query.page);
          } else {
            this.setPage();
          }
        }
      } else {
        this.getAuctionError = true
        this.auctionLoading = false
      }
    }
    this.checkUrl()
    this.articleSearchIndex = createArticleSearchIndex(this.articles);
  },
  async mounted() {
    // every time the view is shown i.e. on a route change
    // if no auction is preselected via the url and there is one preselected in the store, use this
    if (!this.preselectedAuction && this.preselectionData.selectedAuctionId) {
      await this.getAuctions()
      this.auctionLoading = true
      this.search = null
      const auction = this.auctions.find(el => el.id === this.preselectionData.selectedAuctionId);
      if (auction) this.$router.push({ params: { id: auction.code } })
      this.currentAuction = this.auctions.find(el => el.id === this.preselectionData.selectedAuctionId);
      await this.getAuctionDetail(this.currentAuction.id)
    }
    GlobalEvents.subscribeEvent(GlobalEventType.updateArticle, this.handleAuctionDataUpdate)
  },
  async unmounted() {
    GlobalEvents.unsubscribeEvent(GlobalEventType.updateArticle, this.handleAuctionDataUpdate)
  },
  methods: {
    ...mapActions(useRootStore, ['updateUserProfile', 'getArticles', 'updateArticleData', 'fetchCategories', 'SET_TEMP_ALERT']),
    getFormattedDate,
    async addToWishList(article) {
      if (article.wishlist) {
        try {
          await dispatcher.removeFromWishlist(this.currentAuction.id, this.userProfile.id, article.id)
          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Article has been removed from your wishlist'), timeout: 2000 })
          article.wishlist = false
        } catch (e) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error removing that article from the wishlist. Please try again later.') })
        }
      } else {
        try {
          await dispatcher.addToWishlist(this.currentAuction.id, this.userProfile.id, article.id)
          this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('Article has been placed on your wishlist'), timeout: 2000 })
          article.wishlist = true
        } catch (e) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error adding that article to the wishlist. Please try again later.') })
        }
      }
      this.tableKey++
    },
    getFieldName(obj, field) {
      let langTarget
      if (typeof obj[`${field}_i18n`] === 'string' && obj[`${field}_i18n`].length > 0) {
        langTarget = JSON.parse(obj[`${field}_i18n`])
      } else {
        langTarget = obj[`${field}_i18n`]
      }
      if (langTarget && Object.keys(langTarget).length > 0 && langTarget[this.appStoreLang]) {
        return `${field}_i18n`
      }
      return field
    },
    euNumbers,
    changePage(el) {
      // this.page = el
      this.page = el - 1
      this.setPage();
      this.getArticlesData()
      this.$nextTick(() => {
        this.$refs.anchor.scrollIntoView({ behavior: 'smooth' })
      })
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    // Close profile modal without saving
    closeProfileModal () {
      this.$router.push({ name: 'dashboard' })
    },
    openCategoryModal() {
      this.$refs.categoryModal.openModal();
    },
    async getAuctionDetail(auctionID, savedPageNumber = 0, savedCategory = null, getCategories = true, getTimedData = true) {
      this.activeCategory = savedCategory
      this.page = savedPageNumber
      this.currentAuction = { ...this.currentAuction, id: +auctionID }

      await this.getArticles({ auctionID, auction: this.selectedAuctionData })
      try {
        if (getCategories) {
          const categoriesData = await this.fetchCategories();
          // this.categories = categoriesData //TODO why commented out
          this.categoriesError = false
        }
      } catch (e) {
        this.categoriesError = true
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Displaying the categories is currently unavailable due to an technical error. Please try again later.') })
        // throw e
      }

      let commissionBids = []
      const targetAuction = this.auctions.find(el => el.id === auctionID)
      if (this.isAuthenticatedAsUser && targetAuction.commission_bids_enabled && targetAuction.type === AuctionFlavor.live) {
        try {
          const bidsResp = await dispatcher.getAllCommissionBids(auctionID, false)
          commissionBids = bidsResp.commission_bids
          this.commissionError = false
        } catch (e) {
          this.commissionError = true
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('Absentee bid planning is currently unavailable due to an technical error. Please try again later.') })
        }
      }

      let responseAuction = this.auctionsFetchedArticles[`auction_${auctionID}`].map(article => {
        if (!this.categoriesError) {
          for (let category in this.categories) {
            if (!this.categories[category].articlesCount) this.categories[category] = { ...this.categories[category], articlesCount: 0 }
            if (this.categories[category].id === article.category_id) {
              if (this.selectedAuctionData.type === 'sale') {
                if (article.status === '1' && article.instant_buy_possible) this.categories[category].articlesCount++
              } else {
                this.categories[category].articlesCount++
              }
            }
          }
        }
        return {
          ...article,
          commission_bids: commissionBids ? commissionBids.filter(el => el.articles_id === article.id) : []
        }
      })
      if (responseAuction.Message) {
        this.error = responseAuction.Message
        this.auctionLoading = false
        return
      }

      if (this.isAuthenticatedAsUser) {
        try {
          const wishListResp = await dispatcher.getWishlist(auctionID, this.userProfile.id)
          this.wishlistError = false
          this.wishListData = wishListResp

          wishListResp.forEach(el => {
            responseAuction.forEach(article => {
              if (article.id === el.articles_id) {
                article.wishlist = true
                article.wishlistId = el.id
              }
            })
          })
        } catch (e) {
          this.wishlistError = true
          this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('The wishlist is currently unavailable due to a technical error. Please try again later.') })
        }

        this.bidderNumbers = await getBidderNumbersFromUser(this.userProfile.id)
      }

      this.error = null
      if (this.selectedAuctionData.type === 'sale') {
        this.articles = responseAuction.filter(el => +el.status === 1 && el.instant_buy_possible)
        this.articlesSearchFiltered = responseAuction.filter(el => +el.status === 1 && el.instant_buy_possible)
      } else {
        this.articles = responseAuction
        this.articlesSearchFiltered = responseAuction
      }

      if (this.isAuthenticatedAsUser && getTimedData && this.selectedAuctionData.type === 'timed') await this.getTimedData(auctionID)

      this.key++
      this.getArticlesData()
      this.auctionLoading = false
    },
    /**
     * populate the articles that will be shown in the view (if a category is selected, filter)
     * @return {<any>} - articles
     */
    getArticlesData() {
      let articles = [...this.articlesSearchFiltered]
      if (this.showUnsold) {
        articles = articles.filter(el => !el.sold_price)
      }
      if (this.activeCategory) {
        articles = articles.filter(el => el.category_id === this.activeCategory)
        this.getAuctionError = _.isEmpty(articles)
      }
      this.totalItems = articles.length
      this.articlesView = articles.slice(this.page * this.countPerPageDefault, (this.page + 1) * this.countPerPageDefault)
      this.articlesViewUnsliced = articles
    },
    searchArticles,
    /**
     * search in the articles (all of that auction not just the ones on the current page) and save results it in articlesSearchFiltered
     */
    searchHandler: _.debounce(function () {
      this.page = 0
      let articles = [...this.articles]
      if (this.search) {
        this.articlesSearchFiltered = this.searchArticles(this.articleSearchIndex, this.search, articles, this.searchKeys, this.appStoreLang)
      } else {
        this.articlesSearchFiltered = articles
      }
      this.getArticlesData()
    }, timeouts.debounce),
    articleTitle (article) {
      return getTranslatedText(article, 'name', this.appStoreLang)
    },
    articleDescription (article) {
      return getTranslatedText(article, 'description', this.appStoreLang)
    },
    auctionName (auction) {
      return getTranslatedText(auction, 'name', this.appStoreLang)
    },
    setCategory(id) {
      this.activeCategory = id
      if (this.$route.query.categoryId !== this.activeCategory) {
        this.page = 0;
        if (this.activeCategory === null) {
          this.$router.replace({ query: { categoryId: undefined } })
        } else {
          this.$router.replace({ query: { categoryId: id } })
        }
      }
      this.getArticlesData()
    },
    setPage() {
      if (+this.$route.query.page !== (this.page + 1)) this.$router.replace({ query: { ...this.$route.query, page: (this.page + 1) } })
    },
    changePerPage(item) {
      this.countPerPageDefault = item
      this.page = 0
      this.setPage();
      this.getArticlesData()
    },
    openWishList() {
      this.$router.push({ name: 'wishlist', params: { id: this.selectedAuctionData.code } })
    },
    openBidsView() {
      this.$router.push({ name: 'bids', params: { id: this.selectedAuctionData.code } })
    },

    async selectImage(e, images, index) {
      if (e.target.classList.contains('image-item')) {
        e.target.blur()
      } else {
        e.target.parentNode.blur()
      }
      let arr = []
      for (let image of images) {
        const res = await getImageOrFallback(getImageFullUrl(image.image, this.selectedAuctionData.code), image.image)
        arr.push(res)
      }
      this.selectedImages = arr
      this.lightBoxIndex = index
    },

    /**
     * Get all auctions from api and filter according to conditionals
     * @return {any} - Modified auctions
     */
    async getAuctions() {
      try {
        let data
        if (this.isAuthenticatedAsUser) {
          data = await dispatcher.getAllAuctions()
        } else {
          data = await dispatcher.getGuestAuctions()
        }
        this.auctions = data.filter(el => {
          return moment(el.active_until).diff(moment(), 'seconds') > 0 && el.catalogue_enabled !== 'disabled'
        })
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting the auctions. Please try again later') });
        this.error = true;
      }
    },

    async getTimedData(id) {
      try {
        const result = await dispatcher.getArticleTimedStatus(id, "all")
        if (result.timedBids && result.timedBids.length) {
          result.timedBids.forEach(el => {
            this.articles.forEach(article => {
              if (el.article_id === article.id) {
                article.timedBidData = el
              }
            })
            this.articlesSearchFiltered.forEach(article => {
              if (el.article_id === article.id) {
                article.timedBidData = el
              }
            })
          })
        }
        this.timedError = false
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error getting timed data. Please try again later') })
        this.timedError = true
      }
    },
    selectAuction(id) {
      this.auctionLoading = true
      this.search = null
      this.getAuctionDetail(id)
      const auction = this.auctions.find(el => el.id.toString() === id.toString())
      if (auction) this.$router.push({ params: { id: auction.code } })
    },
    async submitBuyArticle() {
      try {
        this.instantBuyLoading = true
        const data = {
          type: 'instant-buy',
          lang: this.appStoreLang,
          article_id: this.selectedArticle.id
        }
        await dispatcher.sendEmailInquiry(this.userProfile.id, data)
        this.instantBuyLoading = false
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('We have received your request and will get back to you as soon as possible') })
        this.$refs.instantBuyModal.closeModal()
        await this.getAuctionDetail(this.currentAuction.id)
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error sending the inquiry. Please try again later') })
        this.instantBuyLoading = false
      }
    },
    openBidModal(article, editMode = false) {
      this.selectedArticle = article
      this.bidDialog = true
      this.editBid = editMode
      this.pickUp = this.currentAuction.no_shipping
    },
    closeBidModal() {
      this.bidDialog = false
      this.selectedArticle = {}
    },
    openTimedBidModal(article) {
      this.selectedArticle = article
      this.$refs.timedBidModal.openModal()
    },
    openPostAuctionSaleModal(article) {
      this.selectedArticle = article
      this.postAuctionSaleModal = true
    },
    closePostAuctionSaleModal() {
      this.selectedArticle = {}
      this.postAuctionSaleModal = false
    },
    openArticleInquiryModal(article) {
      this.selectedArticle = article
      this.$refs.inquiryModal.open()
    },
    buyArticle(article) {
      this.selectedArticle = article
      if (this.selectedAuctionIsSale) {
        this.$refs.instantBuyModal.openModal(`${this.currency} ${euNumbers(article.buy_price)}`)
      } else {
        this.$refs.confirmationModal.openModal()
      }
    },
    async submitBuy() {
      try {
        const result = await dispatcher.instantBuy(this.selectedAuctionData.id, this.selectedArticle.id)
        this.SET_TEMP_ALERT({ flavor: 'success', content: this.$t('You have successfully bought the article') })
        this.updateArticleData({ auction_id: this.selectedAuctionData.id, article: { ...this.selectedArticle, ...result } })
        let articles = this.auctionsFetchedArticles[`auction_${this.selectedAuctionData.id}`]
        this.articles = articles
        this.articlesSearchFiltered = articles
      } catch (e) {
        this.SET_TEMP_ALERT({ flavor: 'error', content: this.$t('There was an error buying the article. Please try again later') })
      } finally {
        this.$refs.confirmationModal.closeModal()
      }
    },
    cancelBuy() {
      this.$refs.confirmationModal.closeModal()
    },
    showImageToggle(id) {
      this.showImages = {
        ...this.showImages,
        [`article${id}`]: true
      }
    },
    openLogin() {
      this.$router.push({ name: 'login', query: { nextUrl: this.$route.fullPath.trim() } })
    },
    openRegistrationModal() {
      this.visibleRegistrationAlert = false;
      this.$refs.registrationModal.openModal()
    },
    openAuctionSignupModal() {
      // TODO why is this neccessary and cannot be used in the component like "selectedAuctionData.require_bid_limit_on_signup"
      this.auctionRequireBidLimit = this.selectedAuctionData.require_bid_limit_on_signup
      this.visibleAuctionSignupAlert = false;
      this.$refs.auctionSignupModal.openModal()
    },
    handleAuctionDataUpdate() {
      const rootStore = useRootStore();
      if (!this.selectedAuctionData) return false
      let articles = rootStore.auctionsFetchedArticles[`auction_${this.selectedAuctionData.id}`]
      //console.log('new values ' + JSON.stringify(articles))
      if (!articles) return
      if (this.selectedAuctionData && this.selectedAuctionData.type === 'sale') {
        this.articles = articles.filter(el => +el.status === 1 && el.instant_buy_possible)
        this.articlesSearchFiltered = articles.filter(el => +el.status === 1 && el.instant_buy_possible)
      } else {
        const updatedArticles = articles.map(el => {
          const prevVal = this.articles.find(article => article.id === el.id)
          return {
            ...prevVal,
            ...el
          }
        })
        this.articles = updatedArticles
        this.articlesSearchFiltered = updatedArticles
      }

      this.key++
      this.getArticlesData()
      if (this.categories.length) {
        for (let category in this.categories) {
          if (!this.categories[category].articlesCount) {
            this.categories[category] = {
              ...this.categories[category],
              articlesCount: 0
            }
          }
          this.categories[category].articlesCount = this.articles.filter(el => el.category_id === this.categories[category].id).length
        }
      }
    }
  },
  watch: {
    selectedDetailedArticle: {
      deep: true,
      handler(val) {
        let index = this.articlesViewUnsliced.findIndex(el => el.id === val.id);
        let page = Math.floor(index / this.countPerPageDefault);
        if (page !== this.page) {
          this.page = page;
          this.getArticlesData();
          let indexA = this.articlesView.findIndex(el => el.id === val.id);
          if (indexA !== -1) this.$refs.article[indexA].scrollIntoView({ block: 'center', behavior: 'smooth' })
        } else {
          let indexA = this.articlesView.findIndex(el => el.id === val.id);
          if (indexA !== -1) this.$refs.article[indexA].scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
      }
    },
    articles: {
      deep: true,
      handler(val) {
        this.articleSearchIndex = createArticleSearchIndex(val);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.catalogue {
  padding-bottom: 50px;
  .catalogue__item-header {
    margin-bottom: 10px;
    min-height: 30px;
  }
  &__list {
    width: calc(100% - 216px);
  }
}
</style>
