<template>
  <div class="user-profile-container" v-if="renderComponent">
    <div class="close-btn-wrapper">
      <v-btn class="close-btn" @click="closeModal()">
        <v-icon class="close-modal-icon">fa-times</v-icon>
      </v-btn>
      <v-btn class="save-btn" :disabled="disabledSave" @click="upsert()" v-if="tab !== 2 && tab !== 3">
        <v-icon class="save-modal-icon">fa-save</v-icon>
      </v-btn>
    </div>
    <v-card-text class="main-card update-profile-container">
      <v-container>
        <v-row>
          <v-col align="center">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              class="user-tabs"
              :vertical="isMobile"
            >
              <v-tab class="tab-title">
                {{ $t('Account') }}
              </v-tab>
              <v-tab class="tab-title">
                {{ $t('Personal data') }}
              </v-tab>
              <v-tab class="tab-title">
                {{ $t('Files') }}
              </v-tab>
              <v-tab class="tab-title">
                {{ $t('Bidding data') }}
              </v-tab>
              <v-tab class="tab-title">
                {{ $t('Change password') }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item class="pb-8">
                <v-card max-width="1100" class="mt-10">
                  <v-card-title>{{ $t('Basic Information') }}</v-card-title>
                  <v-card-text>
                    <div class="d-flex flex-column flex-sm-row">
                      <div class="flex-grow-1 pt-2 pa-sm-2">
                        <v-text-field
                          v-model="user.email"
                          label="Email"
                          :rules="[validationRequiredRule()]"
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>

                        <div class="d-flex flex-column align-start">
                          <v-checkbox v-model="user.doi" dense :label="$t('Email verified')" />
                          <v-btn
                            v-if="!user.doi"
                            @click="sendVerificationEmail"
                            class="text-none"
                            :loading="emailLoading"
                          >
                            <v-icon left small>fa-envelope</v-icon>{{ $t('Send verification email') }}
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>

                <div class="mb-4 text-left" style="max-width: 1100px;">
                  <v-expansion-panels v-model="panel" multiple class="mt-3">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="title">{{ $t('Actions') }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="mb-2">
                          <div class="title">{{ $t('Reset password') }}</div>
                          <div class="subtitle mb-2">{{ $t('Send a reset password email to the user') }}</div>
                          <v-btn
                            class="mb-2 text-none"
                            @click="sendResetEmail"
                            :loading="passwordLoading"
                          >
                            <v-icon left small>fa-envelope</v-icon>{{ $t('Send password reset email') }}
                          </v-btn>
                        </div>

                        <v-divider />

                        <div class="my-2">
                          <div class="error--text title">{{ $t('Danger Zone') }}</div>
                          <v-select
                            class="input-field pt-1 mt-4"
                            :items="userRoles"
                            :label="$t('Role')"
                            v-model="user.role"
                            hide-details
                          />

                          <v-divider />

                          <div class="subtitle mt-3 mb-2">{{ $t('Prevent the user from signing in on the platform') }}</div>
                          <div class="my-2">
                            <v-btn
                              v-if="user.status === UserStatusType.pending || user.status === UserStatusType.locked"
                              color="warning"
                              class="text-none"
                              @click="openEnableUserModal"
                            >
                              <v-icon left small>fa-user-check</v-icon>{{ $t('Enable User') }}
                            </v-btn>
                            <v-btn
                              v-else
                              color="warning"
                              class="text-none"
                              @click="openDisableUserModal"
                            >
                              <v-icon left small>fa-ban</v-icon>{{ $t('Disable User') }}
                            </v-btn>
                          </div>

                          <v-divider />
                          <div
                            class="subtitle mt-3 mb-2"
                          >
                            {{ $t('To delete the user, there must not be any data (i.e. bids)') }}
                          </div>
                          <v-btn class="text-none" color="error" @click="openDeleteUserModal">
                            <v-icon left small>fa-trash</v-icon>{{ $t('Delete User') }}
                          </v-btn>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="title">{{ $t('Metadata') }}</v-expansion-panel-header>
                      <v-expansion-panel-content class="body-2">
                        <span class="font-weight-bold">{{ $t('Created at') }}</span>
                        {{ getDate(user.created_at) }}
                        <br>
                        <span class="font-weight-bold">{{ $t('Last login') }}</span>
                        {{ getDate(user.last_login) }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-col class="main-col-wrapper">
                    <v-col align="center" class="sub-col-wrapper">
                      <v-row>
                        <v-col sm="12" md="6">
                          <div class="v-input input-field v-input--hide-details v-text-field v-text-field--is-booted v-text-field--placeholder no-padding-top mb-7">
                            <div class="v-input__control mt-1">
                              <lang-siwtcher v-if="renderComponent" @on-changed="langSwitcherValue" :selected="user.language" :updateUILanguage="false" theme="light" />
                            </div>
                          </div>
                          <v-text-field
                            type="text"
                            v-model="user.first_name"
                            :label="$t('First name')"
                            placeholder=" "
                            class="input-field"
                            :rules="[validationRequiredRule()]"
                          >
                            <template #message="{ message }">
                              {{ $t(message) }}
                            </template>
                          </v-text-field>
                          <v-text-field
                            type="text"
                            v-model="user.last_name"
                            :label="$t('Last name')"
                            placeholder=" "
                            class="input-field mb-0"
                            :rules="[validationRequiredRule()]"
                          >
                            <template #message="{ message }">
                              {{ $t(message) }}
                            </template>
                          </v-text-field>
                          <v-row>
                            <v-col sm="12" md="6"  class="pt-2">
                              <v-text-field
                                type='text'
                                v-model="user.company_name"
                                :label="$t('Company name')"
                                placeholder=" " class="input-field"/>
                            </v-col>
                            <v-col sm="12" md="6" class="pt-2">
                              <v-text-field
                                type='text'
                                v-model="user.tax_id"
                                :label="$t('Tax ID')"
                                placeholder=" " class="input-field"/>
                            </v-col>
                          </v-row>
                          <v-text-field
                            type="tel"
                            @keydown="inputPhoneHandler"
                            v-model="user.telephone1"
                            :label="$t('Telephone1')"
                            placeholder=" "
                            class="input-field mt-0 pt-0"
                            :rules="[validationRequiredRule()]"
                          >
                            <template #message="{ message }">
                              {{ $t(message) }}
                            </template>
                          </v-text-field>
                          <v-text-field
                            type="tel"
                            hide-details
                            @keydown="inputPhoneHandler"
                            v-model="user.telephone2"
                            :label="$t('Telephone2')"
                            placeholder=" "
                            class="input-field"
                          />
                          <v-checkbox
                            :hide-details="true"
                            class="input-field"
                            v-model="user.allow_advertisements"
                            :label="$t('Allow advertisements')"
                          />
                          <v-checkbox
                            :hide-details="true"
                            class="input-field"
                            v-model="user.enable_wishlist_notifications"
                            :label="$t('Enable notifications for articles on the wishlist during the auction')"
                          />
                        </v-col>
                        <v-col sm="12" md="6">
                          <div class="v-input input-field v-input--hide-details v-text-field v-text-field--is-booted v-text-field--placeholder mb-7">
                            <div class="v-input__control">
                              <v-select
                                hide-details="true"
                                class="select-input profile-select-countries"
                                v-model="user.country" :items="countries"
                                append-icon="fa-angle-down"
                                :label="$t('Country')" item-text="[1]" item-value="[0]" dense
                              />
                            </div>
                          </div>
                          <v-text-field
                            type="text"
                            v-model="user.street"
                            :label="$t('Street')"
                            placeholder=" "
                            class="input-field"
                            :rules="[validationRequiredRule()]"
                          >
                            <template #message="{ message }">
                              {{ $t(message) }}
                            </template>
                          </v-text-field>
                          <v-text-field
                            v-model="user.house_number"
                            :label="$t('Number')"
                            placeholder=" "
                            class="input-field"
                            :rules="[validationRequiredRule()]"
                          >
                            <template #message="{ message }">
                              {{ $t(message) }}
                            </template>
                          </v-text-field>
                          <v-text-field
                            type="text"
                            @keydown="inputZipHandler"
                            v-model="user.zipcode"
                            :label="$t('Zipcode')"
                            placeholder=" "
                            class="input-field"
                            :rules="[validationRequiredRule()]"
                          >
                            <template #message="{ message }">
                              {{ $t(message) }}
                            </template>
                          </v-text-field>
                          <v-text-field
                            type="text"
                            v-model="user.city"
                            :label="$t('City')"
                            placeholder=" "
                            class="input-field mb-3"
                            :rules="[validationRequiredRule()]"
                          >
                            <template #message="{ message }">
                              {{ $t(message) }}
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-card>
              </v-tab-item>
              <v-tab-item style="width: 870px; max-width: 100%;" class="mx-auto pb-9">
                <files-management :files="files" :loading="loading" :user-id="+$route.params.id" />
              </v-tab-item>
              <v-tab-item>
                <v-col align="center">
                  <template v-if="user.id">
                    <v-col class="main-col-wrapper">
                      <v-col align="center" class="sub-col-wrapper" sm="12">
                        <v-data-table
                          :headers="headers"
                          :items="userToAuctionUser"
                          :items-per-page="perPage"
                          sort-by="code"
                          class="elevation-1"
                          mobile-breakpoint="100"
                          style="max-width:920px;"
                          dense
                          @page-count="pageCount = $event"
                          @click:row="editItem"
                          :footer-props="{
                            'items-per-page-all-text': $t('All'),
                            'items-per-page-text': $t('Rows per page'),
                            'page-text': `{0} - {1} ${$t('of')} {2} ${$t('items')}`,
                            'items-per-page-options': []
                          }"
                        >
                          <template v-slot:[`item.notification_auction_start`]="{ item }">
                            <v-icon v-if="item.type === AuctionFlavor.live && (item.notification_auction_start === null || item.notification_auction_start === false)" class="red-color">fa-times</v-icon>
                            <v-icon v-else-if="item.type === AuctionFlavor.live" class="green-color-success">fa-check</v-icon>
                            <span v-else>-</span>
                          </template>
                          <template #top>
                            <v-dialog v-model="dialog" max-width="500px" content-class="test">
                              <v-card>
                                <v-btn class="modal-close-btn icon-with-hover" icon @click="closeEditBidderNumberModal"><v-icon>fa-times</v-icon></v-btn>
                                <v-card-title>
                                  <span class="headline">{{ $t('Edit bidding data') }}</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          v-model="editedItem.code"
                                          :label="$t('Auction code')"
                                          disabled
                                        />
                                        <v-text-field
                                          :value="$t(editedItem.type)"
                                          :label="$t('Type')"
                                          disabled
                                        />
                                        <v-text-field
                                          type="number"
                                          v-model.number="editedItem.bidder_number"
                                          :label="$t('Bidder number')"
                                          @keydown="inputCharHandler"
                                          min="0"
                                        />
                                      <v-select
                                        class="select-input"
                                        v-model="editedItem.user_status"
                                        :items="userStatuses"
                                        :label="$t('Status')"
                                        item-text="label"
                                      />
                                        <v-text-field
                                          v-if="editedItem.type === AuctionFlavor.live"
                                          v-model.number="editedItem.user_bid_limit"
                                          :label="$t('Bid limit requested')"
                                          disabled
                                        />
                                        <v-text-field
                                          v-if="editedItem.type === AuctionFlavor.live"
                                          type="number"
                                          v-model.number="editedItem.bid_limit"
                                          :label="$t('Bid limit')"
                                          @keydown="inputCharHandler"
                                          min="0"
                                        />
                                        <v-checkbox
                                          v-if="editedItem.type === AuctionFlavor.live"
                                          hide-details
                                          class="input-field"
                                          v-model="editedItem.notification_auction_start"
                                          :label="$t('Receive a reminder email before the auction starts')"
                                        />
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions class="justify-center">
                                  <v-btn class="btn primary-btn" @click="saveBidderNumber">
                                    <span>{{ $t('Save') }}</span>
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </template>
                          <template #[`item.type`]="{ item }">
                            {{ $t(item.type) }}
                          </template>
                          <template #[`item.bidder_number`]="{ item }">
                            {{ item.bidder_number || '-' }}
                          </template>
                          <template #[`item.user_bid_limit`]="{ item }">
                            {{ item.user_bid_limit ? euNumbers(item.user_bid_limit) + ' ' + currency : '-' }}
                          </template>
                          <template #[`item.bid_limit`]="{ item }">
                            {{ item.bid_limit ? euNumbers(item.bid_limit) + ' ' + currency : '-' }}
                          </template>
                          <template #[`item.user_status`]="{ item }">
                            {{ $t(item.user_status) }}
                          </template>
                          <template #[`item.actions`]="{ item }">
                            <v-icon
                              small
                              class="mr-2"
                              @click="editItem(item)"
                            >
                              fa-edit
                            </v-icon>
                          </template>
                          <template #no-data>
                            {{ $t('No data available') }}
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-col>
                  </template>
                </v-col>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-col class="relative-position main-col-wrapper">
                    <v-col align="center" class="sub-col-wrapper">
                      <v-text-field
                        type="text"
                        v-model="user.email"
                        :label="$t('Email')"
                        placeholder=" "
                        class="input-field"
                        :class="{'error-border-user': !user.email}"
                        :rules="[validationRequiredRule(), validationEmailRule()]"
                      >
                        <template #message="{ message }">
                          {{ $t(message) }}
                        </template>
                      </v-text-field>
                      <div class="input-field password-strength-block">
                        <v-text-field
                          v-model="user.password"
                          :label="passwordLabel"
                          :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
                          @click:append="showPassword = !showPassword"
                          @keyup="checkPassword"
                          @focus="$refs.passwordField.$el.getElementsByTagName('button')[0].tabIndex = -1"
                          :type="showPassword ? 'text' : 'password'"
                          ref="passwordField"
                          :rules="[validationPasswordRule()]"
                        >
                          <template #message="{ message }">
                            {{ $t(message) }}
                          </template>
                        </v-text-field>
                        <!-- <v-progress-linear  class="strength-bar" :color="score.color" :value="score.value"></v-progress-linear> -->
                        <p v-if="!isIncorrectPassword" class="input-field input-field-error">{{ $t('Password must have at least four different characters (lowercase letter, capital letter, numbers, symbols)') }}</p>
                      </div>
                    </v-col>
                  </v-col>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
      <Footer />
    </v-card-text>

    <confirmation-modal
      :title="$t('Disable User')"
      :text="$t('Are you sure you want to disable this user?')"
      @submit="submitDisableUser"
      @cancel="cancelDisableUser"
      ref="disableConfirmationModal"
    />

    <confirmation-modal
      :title="$t('Enable User')"
      :text="$t('Are you sure you want to enable this user?')"
      @submit="submitEnableUser"
      @cancel="cancelEnableUser"
      ref="enableConfirmationModal"
    />
    <confirmation-modal
      :title="$t('Do you really want to delete this user?')"
      @submit="submitUserDelete"
      @cancel="cancelUserDelete"
      ref="confirmationModal"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { validatePassword } from '@/utils'
import Countries from 'i18n-iso-countries'
import _ from 'lodash'
import Footer from '@/components/footer'
import { mapActions, mapState } from 'pinia'
import FilesManagement from '@/components/blocks/files-management'
import ConfirmationModal from '@/components/modals/confirmation-modal'
import { AuctionFlavor, Role, UserStatusType } from '@/utils/constants'
import { getBidderNumbersFromUser } from '@/services/user';
import frontendValidations from '@/utils/rules';

import en from 'i18n-iso-countries/langs/en.json'
import de from 'i18n-iso-countries/langs/de.json'
import inputHandlers from '@/utils/inputHandlers';
import useRootStore from '@/stores/rootStore';
import { euNumbers } from '@/services/i18n';
import dispatcher from '@/api/dispatch';

Countries.registerLocale(en)
Countries.registerLocale(de)

export default {
  name: 'UpsertUserModal',
  components: {
    Footer,
    FilesManagement,
    ConfirmationModal
  },
  computed: {
    UserStatusType() {
      return UserStatusType
    },
    ...mapState(useRootStore, ['appSettings', 'appStoreLang', 'appLocalization', 'isMobile', 'users']),
    AuctionFlavor() {
      return AuctionFlavor
    },
    userRoles() {
      const roles = []
      for (const role of Object.keys(Role)) {
        roles.push({ value: role, text: this.$t(Role[role]) })
      }
      return roles
    },
    currency () {
      return this.appLocalization.currency
    },
    disabledSave() {
      if (!this.user.email || this.user.email === '' || !this.testEmail(this.user.email) ||
        !this.user.first_name || this.user.first_name === '' || !this.user.last_name ||
        this.user.last_name === '' || this.user.street === '' || this.user.house_number === '' || this.user.zipcode === '' ||
        this.user.city === '' || this.user.telephone1 === '') {
        return true
      }
      if (this.user.id === undefined && (!this.user.password || this.user.password.length <= 0)) {
        return true
      }
      if (this.user.password && this.user.password.length > 0) {
        return !validatePassword(this.user.password);
      }
      return false;
    },
    /* score() {
      if(this.user && this.user.password)
        return passwordStrength(this.user.password)
      else
        return {color:'red',score:0}
    }, */
    passwordLabel() {
      if (this.isAddUser) {
        return this.$t('New password')
      } else {
        return this.$t('New password')
      }
    }
  },
  async mounted() {
    const entries = Object.entries(Countries.getNames(this.$i18n.locale, { select: 'official' }));
    this.countries = entries
    this.tab = 'Personal data'

    if (this.$route.params.id) {
      const result = await dispatcher.getUserData(this.$route.params.id)
      this.user = result;
      await this.getUserBidderNumbers()
      await this.getUserFiles()
    }

    this.currentStatus = this.user.status
  },
  data: function () {
    return {
      panel: true,
      isAddUser: false,
      showPassword: false,
      defaultUser: {
        language: 'german',
        status: '0',
        doi: false,
        languages: ['english', 'german'],
      },
      languages: ['english', 'german'],
      user: {
        password: '',
        language: 'german',
        status: '0'
      },
      items: ['Australia', 'UK', 'Vietnam'],
      countries: [],
      userId: null,
      userStatuses: [{
        label: this.$t('pending'),
        value: 'pending'
      }, {
        label: this.$t(UserStatusType.locked),
        value: UserStatusType.locked
      }, {
        label: this.$t(UserStatusType.unlocked),
        value: UserStatusType.unlocked
      }],
      isIncorrectPassword: true,
      incorrectEmailMessage: 'Incorrect Email',
      loading: false,
      passwordLoading: false,
      emailLoading: false,
      files: [],
      headers: [
        {
          text: this.$t('Auction code'),
          align: 'left',
          sortable: false,
          value: 'code',
          width: 100
        },
        {
          text: this.$t('Type'),
          align: 'left',
          sortable: false,
          value: 'type',
          width: 100
        },
        {
          text: this.$t('Bidder number'),
          sortable: false,
          value: 'bidder_number',
          width: 150
        },
        {
          text: this.$t('Bid limit requested'),
          sortable: false,
          value: 'user_bid_limit',
          width: 160
        },
        {
          text: this.$t('Bid limit'),
          sortable: false,
          value: 'bid_limit',
          width: 90
        },
        {
          text: this.$t('Reminder'),
          sortable: false,
          value: 'notification_auction_start'
        },
        {
          text: this.$t('Status'),
          sortable: false,
          value: 'user_status'
        },
        { text: '', value: 'actions', sortable: false },
      ],
      userToAuctionUser: [],
      editedItem: {
        id: 0,
        type: '',
        bidder_number: 0,
        user_status: 'pending'
      },
      dialog: false,
      perPage: 10,
      page: 1,
      start: 0,
      end: 0,
      pageCount: 0,
      editedIndex: -1,
      renderComponent: true,
      tab: 'Personal data'
    }
  },
  methods: {
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_TEMP_ALERT', 'SET_USERS']),
    validationRequiredRule: frontendValidations.required,
    validationEmailRule: frontendValidations.email,
    validationPasswordRule: frontendValidations.password,
    euNumbers,
    testEmail (val) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(val)
    },
    langSwitcherValue(value) {
      this.user.language = value
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    stringify(data) {
      return JSON.stringify(data, null, 2)
    },
    editItem (item) {
      this.editedIndex = this.userToAuctionUser.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    closeEditBidderNumberModal () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async saveBidderNumber () {
      try {
        if (!_.isEmpty(this.editedItem.bidder_number) && !_.isNumber(this.editedItem.bidder_number)) {
          this.SET_TEMP_ALERT({ flavor: 'error', content: `${this.$t('Please enter a valid number')}` })
          return
        }
        const data = {
          userToAuctionUser: [
            {
              ...this.editedItem,
              bid_limit: this.editedItem.bid_limit || null,
              notification_auction_start: this.editedItem.notification_auction_start
            }
          ]
        }
        await dispatcher.updateUserBidderNumbers(data)
        this.userToAuctionUser[this.editedIndex] = this.editedItem
        await this.getUserBidderNumbers()
        this.closeEditBidderNumberModal()
        this.SET_TEMP_ALERT({
          flavor: 'success',
          content: this.$t('Values changed successfully')
        })
      } catch (e) {
        if (e.data?.error) {
          this.SET_TEMP_ALERT({
            flavor: 'error',
            content: (this.$t(e.data.data[0].errorShortText))
          })
        } else {
          this.SET_TEMP_ALERT({
            flavor: 'error',
            content: this.$t('There was an error changing values')
          })
        }
      }
    },
    async getUserBidderNumbers () {
      this.userToAuctionUser = await getBidderNumbersFromUser(this.$route.params.id)
    },
    checkPassword() {
      if (!this.user.password || this.user.password.length <= 0) {
        this.isIncorrectPassword = true
      }
      this.isIncorrectPassword = validatePassword(this.user.password)
    },
    closeModal () {
      this.$router.push({ name: 'AdminUsers' })
    },

    async upsert (successMessage) {
      this.user.isStatusChanged = this.currentStatus !== this.user.status && this.user.id;

      if (this.user.password && this.user.password.length <= 0) this.user.password = undefined

      const res = await this.upsertUser(this.user, successMessage)

      const updatedUsersList = this.users.map(user => {
        if (user.id === this.user.id) return this.user
        return user
      })
      this.SET_USERS(updatedUsersList)
      if (res) {
        this.closeModal();
      }
    },

    inputCharHandler (e) {
      const expr = /^[a-z]\d*$/
      if (expr.test(e.key) || e.key === ',' || e.key === '-') {
        e.preventDefault()
      }
    },

    // Change language
    updateProp (prop, value) {
      this.user = {
        ...this.user,
        [prop]: value
      }
    },
    inputZipHandler: inputHandlers.inputZipHandler,
    inputPhoneHandler: inputHandlers.inputPhoneHandler,
    async upsertUser(user, successMessage) {
      try {
        this.loading = true;

        if (user.password) {
          if (!validatePassword(user.password)) {
            return false;
          }
        }

        delete (user.isStatusChanged)
        user.id
          ? await dispatcher.updateUser(user.id, _.omit(user, ['index', 'uuid']))
          : await dispatcher.createUser(user)
        this.loading = false;
        if (user.id) {
          this.SET_TEMP_ALERT({
            flavor: 'success',
            content: successMessage || this.$t('Values changed successfully')
          })
        } else {
          this.SET_TEMP_ALERT({
            flavor: 'success',
            content: this.$t('User added successfully')
          })
        }
        return true;
      } catch (e) {
        this.error = true;
        this.loading = false;
        this.SET_TEMP_ALERT({
          flavor: 'error',
          content: this.$t('There was an error changing values')
        })
        return false;
      }
    },
    async getUserFiles() {
      try {
        this.loading = true
        const result = await dispatcher.getFiles(this.$route.params.id)
        this.files = result.files
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    getDate(date) {
      if (!date) return
      const d = new Date(date);
      const ye = d.getFullYear()
      const mo = d.toLocaleString(this.appStoreLang || 'de', { month: 'long', timeZone: 'UTC' });
      const da = d.getDate()
      const h = d.getHours()
      const m = d.getMinutes()
      const s = d.getSeconds()
      const dayPrefix = da === 1 ? 'st' : da === 2 ? 'nd' : da === 3 ? 'rd' : 'th'
      return this.appStoreLang === 'en' ? `${mo} ${da}${dayPrefix}, ${ye} ${h}:${m <= 9 ? '0' + m : m}` : `${da}. ${mo}, ${ye} ${h}:${m <= 9 ? '0' + m : m}:${s <= 9 ? '0' + s : s}`
    },

    openDeleteUserModal() {
      this.$refs.confirmationModal.openModal()
    },

    submitUserDelete() {
      this.$refs.confirmationModal.closeModal()
    },

    cancelUserDelete() {
      this.$refs.confirmationModal.closeModal()
    },

    submitDisableUser() {
      this.user.status = UserStatusType.locked
      this.upsert(this.$t('User has been disabled'))
      this.$refs.disableConfirmationModal.closeModal()
    },

    cancelDisableUser() {
      this.$refs.disableConfirmationModal.closeModal()
    },

    openDisableUserModal() {
      this.$refs.disableConfirmationModal.openModal()
    },

    submitEnableUser() {
      this.user.status = UserStatusType.unlocked
      this.upsert(this.$t('User has been enabled'))
      this.$refs.enableConfirmationModal.closeModal()
    },

    cancelEnableUser() {
      this.$refs.enableConfirmationModal.closeModal()
    },

    openEnableUserModal() {
      this.$refs.enableConfirmationModal.openModal()
    },

    async sendResetEmail() {
      try {
        this.passwordLoading = true
        await dispatcher.resetPassword({ email: this.user.email })
        this.passwordLoading = false
        this.SET_TEMP_ALERT({
          flavor: 'success',
          content: this.$t('Password reset email sent successfully')
        })
      } catch (e) {
        this.passwordLoading = false
        this.SET_TEMP_ALERT({
          flavor: 'error',
          content: this.$t('There was an error sending the password reset email')
        })
      }
    },
    async sendVerificationEmail() {
      try {
        this.emailLoading = true
        await dispatcher.resendActivationEmail({ email: this.user.email })
        this.emailLoading = false
        this.SET_TEMP_ALERT({
          flavor: 'success',
          content: this.$t('Verification email sent successfully')
        })
      } catch (e) {
        this.emailLoading = false
        this.SET_TEMP_ALERT({
          flavor: 'error',
          content: this.$t('There was an error sending the verification email')
        })
      }
    }
  }
}
</script>
<style lang="scss">
.main-col-wrapper {
  .input-field {
    width: 100% !important;
  }
}
  .v-list-item__content {
    text-transform: capitalize;
  }

  .update-profile-container {
    .password-strength-block {
      // background:red;
      input {
        margin-top: unset  !important;
        border: unset  !important;
        border-radius: unset  !important;
        // height: unset  !important;
        // max-height: unset  !important;
        // padding: unset  !important;
      }
      .input-field.input-field-error {
        line-height: 13px;
        margin-bottom: 15px !important;
      }
      .strength-bar {
        top:61px;
      }
    }
    .v-text-field .v-input__append-inner, .v-text-field .v-input__prepend-inner {
      padding-right: 5px;
      margin-top:14px;
    }
  }
</style>
<style lang="scss" scoped>
.user-profile-container.v-card {
  padding-top: 10px !important;
}
.close-btn {
  position: static !important;
}
</style>
